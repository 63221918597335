import { styled } from '@mui/system'
import { Typography, Skeleton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Doughnut } from 'react-chartjs-2'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'

import {
  DonutStatsContainer,
  PrimaryDonutContainer,
  SecondaryDonutsContainer,
  SecondaryDonutStatContainer,
  SecondaryDonutContainer,
  SecondaryDonutsColumn,
} from '../common/StatCard'

import 'react-circular-progressbar/dist/styles.css'

const SecondaryDonutLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.veryDark,
}))

ChartJS.register(ArcElement, Tooltip)

const TalkValueStat = ({ ratingsBreakdown, isLoading }) => {
  const theme = useTheme()
  const { positives, negatives, total } = ratingsBreakdown

  const originalData = [negatives || 0, positives || 0]
  const isAllZero = originalData.every((item) => item === 0)

  const chartData = {
    labels: ['No', 'Yes'],
    datasets: [
      {
        data: isAllZero ? [1] : originalData,
        backgroundColor: isAllZero
          ? [theme.palette.neutral.lightest]
          : [theme.palette.neutral.extraLight, theme.palette.secondary.main],
        borderWidth: 0,
        cutout: '60%',
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <DonutStatsContainer>
      {isLoading ? (
        <Skeleton width={200} height={30} animation="wave" />
      ) : (
        <>
          <PrimaryDonutContainer>
            <Doughnut data={chartData} options={options} />
          </PrimaryDonutContainer>
          <SecondaryDonutsContainer>
            <SecondaryDonutsColumn>
              <SecondaryDonutStatContainer>
                <SecondaryDonutLabel variant="caption">
                  Valuable
                </SecondaryDonutLabel>
                <SecondaryDonutContainer>
                  <CircularProgressbar
                    value={positives}
                    maxValue={total}
                    text={positives || '0'}
                    strokeWidth={10}
                    counterClockwise={true}
                    styles={buildStyles({
                      textSize: '25px',
                      textColor: 'black',
                      pathColor: theme.palette.secondary.main,
                    })}
                  />
                </SecondaryDonutContainer>
              </SecondaryDonutStatContainer>
              <SecondaryDonutStatContainer>
                <SecondaryDonutLabel variant="caption">
                  Not Valuable
                </SecondaryDonutLabel>
                <SecondaryDonutContainer>
                  <CircularProgressbar
                    value={negatives}
                    maxValue={total}
                    text={negatives || '0'}
                    counterClockwise={false}
                    strokeWidth={10}
                    styles={buildStyles({
                      textColor: 'black',
                      pathColor: theme.palette.eventReport.circleChartGrey,
                      textSize: '25px',
                    })}
                  />
                </SecondaryDonutContainer>
              </SecondaryDonutStatContainer>
            </SecondaryDonutsColumn>
          </SecondaryDonutsContainer>
        </>
      )}
    </DonutStatsContainer>
  )
}

export default TalkValueStat
