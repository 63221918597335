import { useEffect, useState, useRef, useContext } from 'react'
import { styled } from '@mui/system'
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  IconButton,
} from '@mui/material'
import { ListItemIcon } from '@material-ui/core'
import ShareIcon from '@mui/icons-material/Share'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import CloseIcon from '@mui/icons-material/Close'
import { isMobileOnly } from 'react-device-detect'

import Api from '../../services/api'

import ReportCardStatImage from './ReportCardStatImage'
import TestimonialStatImage from './TestimonialStatImage'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { eventReportCardShareLink } from '../common/helpers'

import { IoShareOutline } from 'react-icons/io5'

const MobileImg = styled('img')({
  width: '100%',
  height: 'auto',
  display: 'block',
})

const ShareButton = styled(Button)({
  position: 'sticky',
  top: '0',
  right: '0',
  color: 'black',
  textTransform: 'capitalize',
  p: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    svg: {
      marginRight: '5px',
      fontSize: '1.25rem',
    },
    '&:hover': {
      color: 'orange',
    },
  },
})

const ShareEventReportCard = ({
  reportData,
  shareKey,
  isLoading,
  sortedFeedback,
}) => {
  const { setNotification, showFullPageLoader, hideFullPageLoader, authData } =
    useContext(AuthenticationContext)
  const { user } = authData

  const [dataShareOpen, setDataShareOpen] = useState(false)
  const [testimonialShareOpen, setTestimonialShareOpen] = useState(false)
  const [mobileImgSrc, setMobileImgSrc] = useState('')
  const [mobileShareOpen, setMobileShareOpen] = useState(false)

  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const downloadRef = useRef()
  const reportCardShortlink = reportData?.isUserEvent ? user.shortlink : null

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const handleCopy = () => {
    setNotification('Report Link Copied To Clipboard', 'success')
    window.navigator.clipboard.writeText(
      eventReportCardShareLink(reportCardShortlink, shareKey)
    )
  }

  const handleMailerClick = () => {
    const email = ''
    const subject = 'Event Feedback Report'
    const body = `Check out how attendees liked my talk: ${eventReportCardShareLink(
      reportCardShortlink,
      shareKey
    )}`
    const mailData = `mailto:${email}?subject=${
      encodeURIComponent(subject) || ''
    }&body=${encodeURIComponent(body) || ''}`
    window.open(mailData)
  }

  const handleMobileImageDownload = async (selector) => {
    const params = {
      social_share_download: {
        share_key: shareKey,
        element_id: selector,
      },
    }

    showFullPageLoader()

    const res = await Api.getSocialShareImage(params)

    setMobileImgSrc(res)

    hideFullPageLoader()

    setMobileShareOpen(true)
  }

  const handleDownloadReport = async () => {
    if (isMobileOnly || window.innerWidth < 960) {
      handleMobileImageDownload('report-card-share-image')
    } else {
      setDataShareOpen(true)
    }
  }

  const handleDownloadTestimonials = async () => {
    if (isMobileOnly || window.innerWidth < 960) {
      handleMobileImageDownload('testimonials-share-image')
    } else {
      setTestimonialShareOpen(true)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const filteredFeedback = sortedFeedback.filter(
    (feedback) => feedback.public_share === true
  )

  return (
    <>
      <ShareButton
        ref={anchorRef}
        id="report-share-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        variant="text">
        <Typography variant="body2">
          <IoShareOutline />
          Share
        </Typography>
      </ShareButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        style={{ zIndex: '1000' }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleDownloadReport}>
                    <ListItemIcon>
                      <ShareIcon />
                    </ListItemIcon>
                    Download Report
                  </MenuItem>
                  <MenuItem onClick={handleDownloadTestimonials}>
                    <ListItemIcon>
                      <ChatBubbleOutlineIcon />
                    </ListItemIcon>
                    Download Testimonials
                  </MenuItem>
                  <MenuItem onClick={handleCopy}>
                    <ListItemIcon>
                      <ContentCopyIcon />
                    </ListItemIcon>
                    Copy Report Link
                  </MenuItem>
                  <MenuItem onClick={handleMailerClick}>
                    <ListItemIcon>
                      <MailOutlineIcon />
                    </ListItemIcon>
                    Email Report
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Dialog
        open={dataShareOpen}
        fullwidth="true"
        maxWidth="xl"
        sx={{ minWidth: '960px' }}
        onClose={() => setDataShareOpen(false)}>
        <IconButton
          onClick={() => setDataShareOpen(false)}
          sx={{ position: 'absolute', right: 0, top: 0, zIndex: '100' }}>
          <CloseIcon />
        </IconButton>
        <ReportCardStatImage
          reportData={reportData}
          shareKey={shareKey}
          isLoading={isLoading}
          ref={downloadRef}
        />
      </Dialog>
      <Dialog
        open={testimonialShareOpen}
        fullwidth="true"
        maxWidth="xl"
        sx={{ minWidth: '960px' }}
        onClose={() => setTestimonialShareOpen(false)}>
        <IconButton
          onClick={() => setTestimonialShareOpen(false)}
          sx={{ position: 'absolute', right: 0, top: 0, zIndex: '100' }}>
          <CloseIcon />
        </IconButton>
        <TestimonialStatImage
          reportData={reportData}
          sortedFeedback={filteredFeedback}
          shareKey={shareKey}
          ref={downloadRef}
        />
      </Dialog>
      <Dialog
        open={mobileShareOpen}
        fullwidth="true"
        maxWidth="xl"
        onClose={() => setMobileShareOpen(false)}>
        <DialogContent sx={{ padding: 0 }}>
          {mobileImgSrc && (
            <MobileImg src={`data:image/png;base64,${mobileImgSrc}`} />
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ShareEventReportCard
