import { useState, useContext } from 'react'
import { styled } from '@mui/system'
import { TextField } from '@mui/material'

import isEmail from 'validator/lib/isEmail'
import RegistrationErrorFormatter from './RegistrationErrorFormatter'
import { AuthenticationContext } from './authenticationContext'

import Api from '../../services/api'
import { trackSignUp } from '../../services/metrics'
import { getRewardfulReferralId } from '../../utils/rewardful'

import {
  RegistrationContainer,
  InnerContainer,
  CheckoutForm,
  AuthButton,
  Divider,
  InnerDivider,
  FormWrapper,
} from './RegistrationParent'
import RegistrationHeader from './Header'
import TermsOfService from './TermsOfService'
import OAuthButtons, { AUTHENTICATION_TYPES } from './OAuthButtons'
import { GoToLoginPageButton } from './AuthLinkButtons'
import { YellowPaintGraphic } from '../common/SidePaintGraphics'
import * as Messages from '../common/AlertMessages'

const StyledTextField = styled(TextField)({
  // Set input font size to 16px to prevent mobile zoom
  '& .MuiInputBase-input': {
    fontSize: '1rem',
  },
})

const UnpaidRegistrationCheckout = ({
  plan,
  registrationToken,
  referrer,
  referrerId,
  utmParams,
  gaParams,
  groupName,
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [genericInlineError, setGenericInlineError] = useState(null)

  const {
    authData,
    showFullPageLoader,
    hideFullPageLoader,
    handleAuthenticationSuccess,
  } = useContext(AuthenticationContext)

  const handleSubmit = async () => {
    if (!email || !isEmail(email)) {
      return setEmailError('Please fill out a valid email')
    }

    if (!password) {
      return setPasswordError('please fill out a valid password')
    }

    if (password.length < 8) {
      return setPasswordError('Password must be at least 8 characters')
    }

    return await registerUser()
  }

  const registerUser = async () => {
    const { fullPageLoader } = authData

    // Prevent Double Clicks
    if (fullPageLoader?.isActive) {
      return
    }

    showFullPageLoader(Messages.AUTH_MESSAGE)

    const { utm_source, utm_medium, utm_campaign } = utmParams
    const { ga_id, glc_id } = gaParams

    const params = {
      plan_token: plan?.token,
      registration_token: registrationToken,
      registration: {
        email: email,
        password: password,
        referrer: referrer,
        referrer_user_id: referrerId,
        utm_source,
        utm_medium,
        utm_campaign,
        ga_id,
        glc_id,
        rewardful_referrer_id: getRewardfulReferralId(),
      },
    }

    try {
      const res = await Api.registerUnpaidUser(params)
      if (!res.errors && res.auth.auth_token) {
        hideFullPageLoader()
        trackSignUp(res.auth.user?.id, res.auth.user?.email)
        return handleAuthenticationSuccess(res.auth)
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      return setGenericInlineError(
        <RegistrationErrorFormatter
          email={email}
          error={err}
          errorType="talkadot-api"
        />
      )
    }
  }

  return (
    <RegistrationContainer>
      <YellowPaintGraphic />
      <InnerContainer>
        <GoToLoginPageButton />
        <FormWrapper>
          <RegistrationHeader
            referrer={referrer}
            plan={plan}
            groupName={groupName}
            genericWelcomeMessage="Get actionable feedback and generate speaking leads!"
          />
          {genericInlineError}
          <CheckoutForm>
            <StyledTextField
              required
              variant="outlined"
              label="Email"
              type="email"
              fullWidth
              margin="normal"
              value={email}
              error={!!emailError}
              helperText={emailError && emailError}
              onChange={(e) => {
                setGenericInlineError(null)
                setEmailError(null)
                setEmail(e.target.value)
              }}
            />
            <StyledTextField
              required
              fullWidth
              error={!!passwordError}
              margin="normal"
              variant="outlined"
              label="Password"
              type="password"
              autoComplete="current-password"
              value={password}
              helperText={passwordError && passwordError}
              onChange={(e) => {
                setGenericInlineError(null)
                setPasswordError(null)
                setPassword(e.target.value)
              }}
            />
            <TermsOfService />
            <AuthButton
              variant="contained"
              value="submit"
              name="submit"
              color="primary"
              onClick={() => handleSubmit()}>
              Create My Free Account!
            </AuthButton>
          </CheckoutForm>
          <Divider>
            <InnerDivider />
            <div>OR</div>
            <InnerDivider />
          </Divider>
          <OAuthButtons
            authenticationType={AUTHENTICATION_TYPES.REGISTRATION}
            membershipPlan={plan}
            registrationToken={registrationToken}
          />
        </FormWrapper>
      </InnerContainer>
    </RegistrationContainer>
  )
}

export default UnpaidRegistrationCheckout
