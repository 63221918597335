import { useContext } from 'react'

import { styled } from '@mui/system'

import { Notification } from './Notification'
import { usePermissionHelper, ELITE_TIER } from '../../utils/permission_helper'
import { AuthenticationContext } from '../authentication/authenticationContext'

const StyledUpgradeSpan = styled('span')(({ theme }) => ({
  textDecoration: 'underline',
  cursor: 'pointer',
  color: theme.palette.secondary.dark,
}))

const StyledUpgradeLink = styled('a')(({ theme }) => ({
  textDecoration: 'underline',
  cursor: 'pointer',
  color: theme.palette.secondary.dark,
}))

const TemporaryCodeTrialBanner = () => {
  const { getPlanTier } = usePermissionHelper()
  const planTier = getPlanTier()
  const { toggleUpgradeModal } = useContext(AuthenticationContext)

  const makeNotificationContent = () => {
    if (planTier !== ELITE_TIER) {
      return (
        <>
          You can now customize your feedback questions! You have special access
          until Dec 17th at 11:59PM EST.
          <br />
          <br />
          If you’d like to continue to customize your questions,{' '}
          <StyledUpgradeSpan
            onClick={() => {
              toggleUpgradeModal(
                true,
                'Upgrade to unlock full customization features!'
              )
            }}>
            <b>upgrade</b>
          </StyledUpgradeSpan>{' '}
          to Talkadot Elite to lock in your $999/year launch special price.
          <br />
          <br />
          <StyledUpgradeLink
            href="https://talk.talkadot.com/c/resources/new-release-custom-codes"
            target="_blank"
            rel="noreferrer">
            <b>Learn more here.</b>
          </StyledUpgradeLink>
        </>
      )
    } else {
      return (
        <>
          You can now customize more of your feedback questions!{' '}
          <StyledUpgradeLink
            href="https://talk.talkadot.com/c/resources/new-release-custom-codes"
            target="_blank"
            rel="noreferrer">
            <b>Learn more here.</b>
          </StyledUpgradeLink>
        </>
      )
    }
  }

  return (
    <Notification variant="blueWarningFilled" hideIcon={true} hideClose={false}>
      {makeNotificationContent()}
    </Notification>
  )
}

export default TemporaryCodeTrialBanner
