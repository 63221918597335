import { useEffect, useState, forwardRef } from 'react'
import { styled } from '@mui/system'
import { Typography, Button } from '@mui/material'

import SocialShareTemplate from './SocialShareTemplate'
import { trackSocialTestimonialShare } from '../../services/metrics'
import { downloadComponentAsPng } from '../common/helpers'

import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri'

const ReportDownloadContainer = styled('div')({
  '@media (max-width:960px)': {
    marginTop: '-2000px',
    position: 'absolute',
  },
})

const FeedbackList = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showTalkadotBranding',
})(({ showTalkadotBranding }) => ({
  display: 'flex',
  flex: 1,
  textAlign: 'left',
  flexDirection: 'column',
  marginBottom: showTalkadotBranding ? '60px' : '30px',
}))

const FeedbackContent = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'fontScale' && prop !== 'maxLines',
})(({ fontScale, maxLines }) => ({
  flex: '1',
  padding: '0px 10px',
  fontSize: `${fontScale * 20}px`,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: maxLines,
  WebkitBoxOrient: 'vertical',
}))

const OpenQuoteMark = styled(RiDoubleQuotesL)({
  fontSize: '24px',
})

const CloseQuoteMark = styled(RiDoubleQuotesR)({
  fontSize: '24px',
  alignSelf: 'flex-end',
})

const DownloadButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(9),
}))

// Should really do this by rows, and round up
// 11 available rows
const TestimonialStatImage = forwardRef(
  (
    { reportData, sortedFeedback, shareKey, customPickedFeedback = null },
    ref
  ) => {
    const showTalkadotBranding = reportData?.showBranding
    const [priorityFeedback, setPriorityFeedback] = useState([])
    const [totalRowCount, setTotalRowCount] = useState(0)

    // Can fit an extra row without the logo
    const NEW_ROW_BUFFER = 3 // amount of rows a new quote requires
    const CHARACTERS_PER_ROW = 60 // total amount of characters at 22px size a row can fit
    const MAX_FONT_SCALE = 1.6 // multiple of base fontSize of 20px

    let AVAILABLE_ROWS = showTalkadotBranding ? 14 : 15

    const calcFeedback = (sortedFeedback) => {
      let currentRowCount = 0

      let totalFeedback = []

      for (const feedback of sortedFeedback) {
        const feedbackRows = Math.ceil(
          feedback.text.length / CHARACTERS_PER_ROW
        )

        if (
          feedbackRows + currentRowCount <= AVAILABLE_ROWS ||
          (currentRowCount === 0 && feedbackRows > AVAILABLE_ROWS)
        ) {
          totalFeedback.push(feedback)
          currentRowCount += feedbackRows + NEW_ROW_BUFFER
        } else {
          break
        }
      }
      setPriorityFeedback(totalFeedback)
      setTotalRowCount(currentRowCount)
    }
    useEffect(() => {
      if (customPickedFeedback?.length > 0) {
        calcFeedback(customPickedFeedback)
      } else {
        calcFeedback(sortedFeedback)
      }
    }, [reportData, sortedFeedback, customPickedFeedback])

    let fontScale = 1

    if (reportData?.organization) {
      AVAILABLE_ROWS--
    }
    if (reportData?.talkTitle) {
      AVAILABLE_ROWS--
    }

    if (totalRowCount > 0 && totalRowCount < AVAILABLE_ROWS) {
      const rowRatio =
        AVAILABLE_ROWS / totalRowCount > 1 ? AVAILABLE_ROWS / totalRowCount : 1

      fontScale = rowRatio < MAX_FONT_SCALE ? rowRatio : MAX_FONT_SCALE
    }

    const renderFeedback = (feedback, index) => (
      <div key={index} className="report-card-feedback-list__item">
        <div className="report-card-feedback-list__item-inner">
          <OpenQuoteMark />
          <FeedbackContent
            variant="body2"
            component="div"
            fontScale={fontScale}
            maxLines={AVAILABLE_ROWS}>
            {feedback.text}
          </FeedbackContent>
          <CloseQuoteMark />
        </div>
      </div>
    )

    return (
      <ReportDownloadContainer className="report-download-container">
        <SocialShareTemplate
          reportData={reportData}
          shareKey={shareKey}
          downloadRef={ref}
          color="orange"
          downloadId="testimonials-share-image">
          <FeedbackList
            // TODO: remove className and dependent styling
            className="report-card-feedback-list"
            showTalkadotBranding={showTalkadotBranding}>
            {priorityFeedback?.map((feedback, i) => {
              return renderFeedback(feedback, i)
            })}
          </FeedbackList>
        </SocialShareTemplate>
        <DownloadButton
          variant="contained"
          color="secondary"
          onClick={() => {
            trackSocialTestimonialShare()
            downloadComponentAsPng(ref, `talkadot-testimonial-share`)
          }}>
          Click To Download
        </DownloadButton>
      </ReportDownloadContainer>
    )
  }
)

export default TestimonialStatImage
