import { styled } from '@mui/system'
import { Typography, Skeleton } from '@mui/material'
import { ReactComponent as AttendeesIcon } from '../../icons/attendeesIcon.svg'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  color: theme.palette.neutral.veryDark,
}))

const TotalAttendeesStat = ({ totalResponses, isLoading }) => (
  <Container>
    <>
      <AttendeesIcon />
      {isLoading ? (
        <Skeleton width={50} height={30} />
      ) : (
        <StyledTypography variant="h3">
          {totalResponses || '-'}
        </StyledTypography>
      )}
    </>
  </Container>
)

export default TotalAttendeesStat
