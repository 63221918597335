import {
  useContext,
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { styled } from '@mui/system'
import { Typography, Button, IconButton, Tooltip } from '@mui/material'

import { ReactComponent as EyeIcon } from '../../icons/eyeIcon_16x16.svg'
import { ReactComponent as EyeOffIcon } from '../../icons/eyeOffIcon_16x16.svg'
import { ReactComponent as AiIcon } from '../../icons/aiIconOutline.svg'

import Api from '../../services/api'
import LoadingSpinner from '../common/LoadingSpinner'
import { AuthenticationContext } from '../authentication/authenticationContext'

import { usePermissionHelper, ELITE_TIER } from '../../utils/permission_helper'

const EventSummaryContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: theme.spacing(2.5),
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
  },
}))

const FeedbackSummaryContainer = styled('div')(({ theme }) => ({
  gridColumn: 'span 2',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2, 0),
}))

const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2, 0),
  borderBottom: `3px solid ${theme.palette.secondary.main}`,
}))

const StyledHeader = styled(Typography)(({ theme }) => ({
  margin: 0,
  color: theme.palette.neutral.veryDark,
}))

const HeaderActions = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}))

const ToggleQuestionButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isPublic',
})(({ theme, isPublic }) => ({
  svg: {
    path: {
      stroke: isPublic
        ? theme.palette.neutral.semiLight
        : theme.palette.error.semiDark,
    },
  },
}))

const StyledSummary = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.veryDark,
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(2),
}))

const KeyTakeawaysHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.veryDark,
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
}))

const KeyTakeaways = styled('ul')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  margin: 0,
  marginBottom: theme.spacing(2),
  listStyleType: 'none',
  li: {
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingLeft: '1em',
  },
  'li::before': {
    content: '"•"',
    fontSize: '0.875rem',
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
}))

const Footer = styled('div')({
  display: 'flex',
})

const FooterTextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const FooterText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
  color: theme.palette.neutral.main,
  height: '100%',
}))

const GenerateButton = styled(Button)(({ theme }) => ({
  width: 'max-content',
  fontSize: '0.875rem',
  fontWeight: theme.fontWeights.regular,
  padding: theme.spacing(1),
  verticalAlign: 'baseline',
}))

const UpgradeButton = styled(Button)(({ theme }) => ({
  width: 'max-content',
  fontSize: '0.875rem',
  fontWeight: '400',
  padding: `0 ${theme.spacing(1)}`,
}))

const FeedbackUpgradeToUseFeature = () => {
  return (
    <StyledSummary variant="body1">
      This feature is only available to users on the Talkadot Elite plan.
    </StyledSummary>
  )
}

const FeedbackContentLoading = () => {
  return (
    <>
      <StyledSummary variant="body1">
        <LoadingSpinner />
      </StyledSummary>
    </>
  )
}

const FeedbackContentDoesNotExist = ({
  hasEnoughResponses,
  generateSummary,
  summaryLoading,
}) => {
  ''
  return (
    <StyledSummary variant="body1">
      {hasEnoughResponses ? (
        <>
          There are enough responses to generate a summary for this event.
          <GenerateButton
            variant="text"
            color="secondary"
            onClick={generateSummary}
            disabled={summaryLoading}
          >
            Generate a summary now?
          </GenerateButton>
        </>
      ) : (
        'The event does not have enough responses. A summary can not be generated.'
      )}
    </StyledSummary>
  )
}

const FeedbackContentSummary = ({ summary, key_takeaways }) => {
  return (
    <>
      <StyledSummary variant="body1">{summary}</StyledSummary>
      <KeyTakeawaysHeader variant="body1">
        Presentation Outcomes Achieved:
      </KeyTakeawaysHeader>
      <KeyTakeaways>
        {key_takeaways.map((keyTakeaway, index) => (
          <li key={index}>
            <Typography variant="body1">{keyTakeaway}</Typography>
          </li>
        ))}
      </KeyTakeaways>
    </>
  )
}

const FeedbackContentError = ({ status }) => {
  const errorMessage =
    status === 'error_not_enough_feedback'
      ? 'There are not enough responses to generate a summary for this event'
      : 'There was an issue generating this summary. Please try again.'

  return <StyledSummary variant="body1">{errorMessage}</StyledSummary>
}

const MAX_TRIES = 20
const POLLING_INTERVAL = 2000 // 2 seconds

const SUMMARY_DATA_ERROR_STATE = {
  status: 'error',
  headline: null,
  summary: null,
  key_takeaways: null,
}

const FeedbackSummaryParent = ({
  initialSummaryData,
  shareKey,
  speakerName,
  toggleVisibility,
  isPublic,
  hasEnoughResponses,
  hasNewResponses,
  isEventOwner,
  isUserEvent,
}) => {
  const [summaryLoading, setSummaryLoading] = useState(false)
  const [summaryData, setSummaryData] = useState(initialSummaryData)
  const pollingRef = useRef(null)
  const triesRef = useRef(0)
  const { setNotification, toggleUpgradeModal } = useContext(
    AuthenticationContext
  )
  const { getPlanTier } = usePermissionHelper()

  const { summary, key_takeaways, headline, status } = summaryData || {}

  const inProgress = status === 'in_progress'
  const complete = status === 'complete'
  const notEnoughFeedbackError = status === 'error_not_enough_feedback'

  const summaryExists = summaryData && summaryData.status !== 'not_started'

  const isEliteUser = getPlanTier() === ELITE_TIER

  useEffect(() => {
    setSummaryLoading(inProgress)
  }, [inProgress])

  useEffect(() => {
    return () => {
      if (pollingRef.current) clearTimeout(pollingRef.current)
    }
  }, [])

  const stopPolling = useCallback(() => {
    if (pollingRef.current) {
      clearTimeout(pollingRef.current)
      pollingRef.current = null
    }
    triesRef.current = 0
  }, [])

  const pollUntilSummaryGenerated = useCallback(() => {
    return new Promise((resolve, reject) => {
      const poll = async () => {
        if (triesRef.current >= MAX_TRIES) {
          stopPolling()
          resolve({ status: 'stopped', tries: triesRef.current })
          return
        }

        const shouldStopPolling = (latestSummaryData) => {
          return latestSummaryData && latestSummaryData.status !== 'in_progress'
        }

        const reqParams = {
          event_report_card_summary: {
            share_key: shareKey,
          },
        }

        try {
          const res = await Api.getEventReportCardSummary(reqParams)
          if (!res.errors) {
            const latestSummaryData = res.report_card_summary
            if (shouldStopPolling(latestSummaryData)) {
              setSummaryData(latestSummaryData)
              stopPolling()
              resolve({ status: 'completed', data: latestSummaryData })
              return
            }
            triesRef.current += 1
            pollingRef.current = setTimeout(poll, POLLING_INTERVAL)
          } else {
            stopPolling()
            reject(res.errors)
          }
        } catch (err) {
          stopPolling()
          reject(err)
        }
      }

      setTimeout(poll, POLLING_INTERVAL) // Start polling
    })
  }, [shareKey, stopPolling])

  const generateSummary = useCallback(async () => {
    const reqParams = {
      event_report_card_summary: {
        share_key: shareKey,
      },
    }

    const res = await Api.generateEventReportCardSummary(reqParams)
    if (!res.errors) {
      setSummaryData(res.report_card_summary)
    } else {
      throw res.errors
    }
  }, [shareKey])

  const handleGenerateSummary = useCallback(async () => {
    setSummaryLoading(true)
    try {
      await generateSummary()
      await pollUntilSummaryGenerated()
    } catch (err) {
      setSummaryData(SUMMARY_DATA_ERROR_STATE)
      setNotification(err)
    } finally {
      setSummaryLoading(false)
    }
  }, [generateSummary, pollUntilSummaryGenerated, setNotification])

  const handleToggleVisibility = async () => {
    setSummaryLoading(true)
    await toggleVisibility()
    setSummaryLoading(false)
  }

  const body = useMemo(() => {
    if (isEventOwner && !isEliteUser) return <FeedbackUpgradeToUseFeature />
    if (!summaryExists)
      return (
        <FeedbackContentDoesNotExist
          hasEnoughResponses={hasEnoughResponses}
          generateSummary={handleGenerateSummary}
          summaryLoading={summaryLoading}
        />
      )

    if (inProgress) return <FeedbackContentLoading />
    if (complete)
      return (
        <FeedbackContentSummary
          summary={summary}
          key_takeaways={key_takeaways}
        />
      )
    return <FeedbackContentError status={status} />
  }, [
    complete,
    handleGenerateSummary,
    hasEnoughResponses,
    inProgress,
    isEliteUser,
    isEventOwner,
    key_takeaways,
    status,
    summary,
    summaryExists,
    summaryLoading,
  ])

  const title = useMemo(() => {
    return (
      <StyledHeader variant="h5">
        {complete ? `Summary: ${headline}` : 'Summary of this talk'}
      </StyledHeader>
    )
  }, [complete, headline])

  const footer = useMemo(() => {
    if (isEventOwner && !isEliteUser)
      return (
        <Footer>
          <AiIcon />
          <FooterTextContainer>
            <UpgradeButton
              variant="text"
              color="secondary"
              onClick={() =>
                toggleUpgradeModal(true, 'Upgrade to Generate AI Summary!')
              }
            >
              Upgrade to Talkadot Elite to generate a summary for this Talk.
            </UpgradeButton>
          </FooterTextContainer>
        </Footer>
      )

    if (!hasEnoughResponses) return null
    if (notEnoughFeedbackError) return null
    if (!summaryExists) return null

    const tooltipText = `This AI-generated summary captures key insights from attendee feedback for ${isEventOwner ? 'your' : `${speakerName}'s`} talk. Summaries are created when there are at least 10 detailed testimonials to ensure the results are meaningful and reflective of audience sentiment.`
    return (
      <Footer>
        <AiIcon />
        <FooterTextContainer>
          <FooterText variant="caption">
            <Tooltip arrow title={tooltipText}>
              Summary {inProgress ? 'being generated' : 'generated'} based on{' '}
              {speakerName}'s attendee testimonials
            </Tooltip>
          </FooterText>
        </FooterTextContainer>
      </Footer>
    )
  }, [
    hasEnoughResponses,
    inProgress,
    isEliteUser,
    isEventOwner,
    notEnoughFeedbackError,
    speakerName,
    summaryExists,
    toggleUpgradeModal,
  ])

  if (!isUserEvent) return null
  if (!isEventOwner && !complete) return null

  return (
    <EventSummaryContainer>
      <FeedbackSummaryContainer>
        <HeaderContainer>
          {title}
          {isEventOwner && isEliteUser && (
            <HeaderActions>
              <Tooltip
                title={
                  isPublic
                    ? 'Toggle to hide talk summary on public report'
                    : 'Toggle to make summary visible on public report'
                }
                arrow
              >
                <ToggleQuestionButton
                  onClick={handleToggleVisibility}
                  disabled={summaryLoading}
                  isPublic={isPublic}
                >
                  {isPublic ? <EyeIcon /> : <EyeOffIcon />}
                </ToggleQuestionButton>
              </Tooltip>
              {/* TODO: add ellipsis dropdown */}
            </HeaderActions>
          )}
        </HeaderContainer>

        {body}

        {footer}
      </FeedbackSummaryContainer>
    </EventSummaryContainer>
  )
}

export default FeedbackSummaryParent
