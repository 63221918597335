import { useEffect, useContext, useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import moment from 'moment'
import { styled } from '@mui/system'
import {
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TextField,
  Autocomplete,
  Switch,
  Breadcrumbs,
  Skeleton,
} from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { AdminContext } from './adminContext'
import { AuthenticationContext } from '../authentication/authenticationContext'
import Api from '../../services/api'

import { removeEmptysAndNulls, formatDate, handleCopy } from '../common/helpers'
import { FullOrganizationsList } from '../onboarding/referralsHelper'
import {
  OFFER_CODE_LIMIT,
  LEADS_LIMIT,
  TEAMS,
} from '../../utils/permission_helper'

import DeleteUserParent from './DeleteUserParent'
import AdminUserNotes from './AdminUserNotes'
import { ImpersonateUserAction } from './AdminParent'

import { Notification } from '../common/Notification'
import PageHeader from '../common/PageHeader'
import PageContainer from '../common/PageContainer'

const MembershipSelect = styled(Select)({
  minWidth: '250px',
})

const INITIAL_USER_STATE = {
  membership: {},
  membership_plan: {},
  referralPayoutSettings: {
    id: null,
    hasPayoutInfo: null,
    optOut: null,
  },
  availableMembershipStatusus: [],
  availableMembershipPlans: {
    legacyPlans: [],
    basePlans: [],
    referralPlans: [],
  },
  notes: [],
}

const Card = styled('div')(({ theme }) => ({
  marginTop: '30px',
  marginBottom: '30px',
  background: 'white',
  padding: '15px',
}))

const CardHeader = styled('div')({
  marginBottom: '15px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const ActionsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '25px',
  gap: 10,
})

const StyledTable = styled(Table)({
  border: 'none',
  tr: {
    border: 'none',
  },
  td: {
    border: 'none',
  },
})

const AffiliateActionContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
})

const CopyAffiliateLinkAction = ({ user }) => {
  const [copied, setCopied] = useState(false)
  const handleClick = () => {
    handleCopy(user.referrerLink)
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }

  return (
    <Tooltip placement="top" title={copied ? 'Copied!' : 'Copy Referral Link'}>
      <AffiliateActionContainer onClick={handleClick}>
        {user.referrerLink}
        <ShareIcon />
      </AffiliateActionContainer>
    </Tooltip>
  )
}

const ShowUserParent = () => {
  const [pageLoading, setPageLoading] = useState(false)
  const [editReferralOpen, setEditReferralOpen] = useState(false)
  const [user, setUser] = useState(INITIAL_USER_STATE)
  const [newPlan, setNewPlan] = useState({})
  const [trialDaysRemaining, setTrialDaysRemaining] = useState('')
  const [membershipStatus, setMembershipStatus] = useState('')
  const [referrerUsers, setReferrerUsers] = useState([])
  const [selectedReferrerUser, setSelectedReferrerUser] = useState({})
  const [selectedReferrerOrganization, setSelectedReferrerOrganization] =
    useState({})
  const [userNotesLoading, setUserNotesLoading] = useState(false)
  const [noteEditorOpen, setNoteEditorOpen] = useState(false)
  const [editorValue, setEditorValue] = useState('')
  const [activeNote, setActiveNote] = useState(null)

  let urlParams = useParams()
  let history = useHistory()

  const { deleteSpeaker } = useContext(AdminContext)

  const { setNotification, authData } = useContext(AuthenticationContext)

  const resetMembershipChanges = () => {
    setMembershipStatus('')
    setTrialDaysRemaining('')
    setNewPlan({})
  }

  const humanReadableLimit = (limit) => {
    if (!limit) {
      return 0
    }

    if (limit > 0) {
      return limit
    }

    return 'Unlimited'
  }

  const humanReadablePrice = (price) => {
    return price ? `$${price}/year` : 'FREE'
  }

  const makeSingleRowPlanDetails = (plan) => {
    return (
      <Typography>
        {plan.name} - {plan.internalName} - {humanReadablePrice(plan.price)} -{' '}
        <b>{humanReadableLimit(plan.leadsLimit)} leads</b>
      </Typography>
    )
  }

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setPageLoading(true)
        const res = await Api.adminFetchSpeaker(urlParams.userId)
        if (!res.error) {
          setUser(res)
          setPageLoading(false)
        } else {
          throw res.error
        }
      } catch (err) {
        setPageLoading(false)
        alert(err)
      }
    }

    const fetchReferralUserOptions = async () => {
      try {
        const res = await Api.getReferralUserOptions()

        if (!res.errors) {
          const users = res
            .filter((refUser) => refUser?.id !== user?.id)
            .map((refUser) => ({
              id: refUser.id,
              label: `${refUser.first_name} ${refUser.last_name} - ${refUser.email}`,
              fullName: `${refUser.first_name} ${refUser.last_name}`,
            }))

          setReferrerUsers(users)
        } else {
          throw res.errors
        }
      } catch (err) {
        alert(
          "Something went wrong loading the referral options.  Try refreshing the page - if that doesn't work, ask the devs!"
        )
      }
    }

    fetchReferralUserOptions()
    fetchUser()

    return () => {
      setUser(INITIAL_USER_STATE)
      setReferrerUsers([])
    }
  }, [])

  const updateUser = async (user, updateParams) => {
    const params = {
      user: {
        ...updateParams,
      },
    }

    try {
      const res = await Api.updateAdminUser(params, user.id)

      if (!res.errors && !res.error) {
        setUser(res)
        setEditReferralOpen(false)
        setSelectedReferrerOrganization({})
        setSelectedReferrerUser({})
        setNotification('User successfully updated', 'success')
      }
    } catch (err) {
      setEditReferralOpen(false)
      setNotification(err)
    }
  }
  const updateReferralPayoutSetting = async (payoutSettingId, updateParams) => {
    try {
      const res = await Api.editAdminReferralPayoutSetting(payoutSettingId, {
        referral_payout_setting: {
          ...updateParams,
        },
      })

      if (!res.error && !res.errors) {
        setUser(res)
      } else {
        throw res.error
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const handleUpdateMembership = async () => {
    if (!newPlan.id && !trialDaysRemaining && !membershipStatus) {
      return
    }

    // Note:
    // This is a hack in the select menu to not try and submit if we accidentlly select a header row
    if (newPlan.isParentItem) {
      return
    }

    const updateParams = removeEmptysAndNulls({
      membership_plan_id: newPlan.id ? newPlan.id : null,
      trial_days_remaining: trialDaysRemaining ? trialDaysRemaining : null,
      status: membershipStatus ? membershipStatus : null,
    })

    try {
      const res = await Api.updateMembership(
        { membership: updateParams },
        user.membership.id
      )

      if (!res.errors && !res.error) {
        setUser(res)
        resetMembershipChanges()
        setNotification('Membership successfully updated!', 'success')
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const handleAddNote = () => {
    setNoteEditorOpen(true)
  }

  const handleEditNote = () => {
    setEditorValue(activeNote.content)
    setNoteEditorOpen(true)
  }

  const handleCloseEditor = () => {
    setNoteEditorOpen(false)
    setEditorValue('')
    setActiveNote(null)
  }

  const createNewNote = async (note) => {
    try {
      const res = await Api.addAdminUserNote({
        user_note: {
          content: editorValue,
          user_id: user.id,
          created_by: authData.user.id,
          last_edited_by: authData.user.id,
        },
      })

      if (!res.errors && !res.error) {
        setUser({
          ...user,
          notes: [...user.notes, res],
        })
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const updateNote = async () => {
    try {
      const res = await Api.updateAdminUserNote(activeNote.id, {
        user_note: {
          content: editorValue,
          user_id: user.id,
          last_edited_by: authData.user.id,
        },
      })

      if (!res.errors && !res.error) {
        setUser({
          ...user,
          notes: user.notes.map((note) =>
            note.id === activeNote.id ? res : note
          ),
        })
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const handleSaveNote = async () => {
    setUserNotesLoading(true)

    if (!editorValue) {
      setUserNotesLoading(false)
      return
    }

    if (!activeNote?.id) {
      createNewNote()
    } else {
      updateNote()
    }

    setActiveNote(null)
    setNoteEditorOpen(false)
    setEditorValue('')
    setUserNotesLoading(false)
  }

  const handleDeleteNote = async () => {
    if (!activeNote) return

    try {
      setUserNotesLoading(true)

      const data = {
        user_note: {
          user_id: user.id,
        },
      }

      const res = await Api.deleteAdminUserNote(activeNote.id, data)

      if (!res.errors) {
        setUser((prev) => ({
          ...prev,
          notes: prev.notes.filter((note) => note.id !== activeNote.id),
        }))
        setActiveNote(null)
        setUserNotesLoading(false)
      } else {
        throw res.errors
      }
    } catch (error) {
      setActiveNote(null)
      setUserNotesLoading(false)
      console.error(error)
    }
  }

  const noReferrerSelected =
    !selectedReferrerUser?.id && !selectedReferrerOrganization?.label
  const moreThanOneReferrerSelected =
    selectedReferrerUser?.id && selectedReferrerOrganization?.label
  const referrerUserSelected = selectedReferrerUser?.id
  const referrerOrganizationSelected = selectedReferrerOrganization?.label

  const organizationReferralList = FullOrganizationsList.map((orgItem, i) => ({
    label: orgItem,
    id: i,
  }))

  const findByPermissionType = (permissions, permissionType) => {
    return permissions?.find((permission) => permission.type === permissionType)
  }

  const leadsLimit = findByPermissionType(
    user.membership_plan?.permissions,
    LEADS_LIMIT
  )?.limit

  const codesLimit = findByPermissionType(
    user.membership_plan?.permissions,
    OFFER_CODE_LIMIT
  )?.limit

  const teamsLimit = findByPermissionType(
    user.membership_plan?.permissions,
    TEAMS
  )?.limit

  // Only allow editing of trial days if the plan is currently on trial
  // or if we are giving someone a trial of pro and have set to trialing_no_cc
  const canEditTrialDays =
    user.membership?.isTrialing || membershipStatus === 'trialing_no_cc'

  const updateReferral = () => {
    if (noReferrerSelected) {
      return alert('You did not select a valid referrer to update')
    }

    if (moreThanOneReferrerSelected) {
      return alert('You must select only 1 referrer for this user')
    }

    if (referrerUserSelected) {
      return updateUser(user, {
        referrer_user_id: selectedReferrerUser.id,
        referrer: selectedReferrerUser.fullName,
      })
    }

    if (referrerOrganizationSelected) {
      return updateUser(user, {
        // Set this to nil in the DB
        referrer_user_id: '',
        referrer: selectedReferrerOrganization.label,
      })
    }
  }

  const handleDeleteSpeaker = async () => {
    await deleteSpeaker(user.id)
    history.push('/admin')
  }

  const renderLoadingSkeleton = () =>
    Array.from({ length: 6 }).map((_, index) => (
      <TableRow key={index}>
        {Array.from({ length: 2 }).map((_, cellIndex) => (
          <TableCell key={cellIndex}>
            <Skeleton />
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    ))

  return (
    <PageContainer>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to={'/admin'}>Admin Dashboard</Link>
        <Typography>
          {user.first_name} {user.last_name}
        </Typography>
      </Breadcrumbs>
      <br />
      <PageHeader header={`${user.first_name} ${user.last_name}`} />
      <Card>
        <CardHeader>
          <Typography variant="h5" component="h1">
            Speaker Details
          </Typography>
          <ActionsContainer>
            <ImpersonateUserAction user={user} variant="button" />
            <DeleteUserParent
              user={user}
              deleteSpeaker={handleDeleteSpeaker}
              variant="button"
            />
          </ActionsContainer>
        </CardHeader>
        <TableContainer>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell>Attribute</TableCell>
                <TableCell>Value</TableCell>
                <TableCell align="right" sx={{ paddingRight: '15px' }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageLoading ? (
                renderLoadingSkeleton()
              ) : (
                <>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>{user.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Initial Sign Up Date</TableCell>
                    <TableCell>{formatDate(user.created_at)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Referral Link</TableCell>
                    <TableCell>
                      <CopyAffiliateLinkAction user={user} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Referrer</TableCell>
                    <TableCell>
                      <div>
                        {user.referrerUser
                          ? `${user.referrerUser?.first_name} ${user.referrerUser?.last_name}`
                          : user.referrer}
                      </div>
                      <div>{user.referrerUser?.email}</div>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        onClick={() => setEditReferralOpen(true)}>
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Have Referral Payout Info?</TableCell>
                    <TableCell>
                      <Switch
                        onChange={(e) =>
                          updateReferralPayoutSetting(
                            user.referralPayoutSettings.id,
                            {
                              has_payout_info: e.target.checked,
                              payout_type: 'ach',
                            }
                          )
                        }
                        checked={user.referralPayoutSettings?.hasPayoutInfo}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Part of Community?</TableCell>
                    <TableCell>{user.isCircleMember ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Card>
      <Card>
        <CardHeader>
          <Typography variant="h5" component="h1">
            Membership Details
          </Typography>
        </CardHeader>
        <TableContainer>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <b>Attribute</b>
                </TableCell>
                <TableCell align="left">
                  <b>Current</b>
                </TableCell>
                <TableCell align="left">
                  <b>Proposed Change</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageLoading ? (
                renderLoadingSkeleton()
              ) : (
                <>
                  <TableRow>
                    <TableCell>Membership Plan</TableCell>
                    <TableCell align="left">
                      {user.membership_plan?.name}
                    </TableCell>
                    <TableCell align="left">
                      <MembershipSelect
                        value={newPlan.id ? newPlan : ''}
                        displayEmpty
                        fullWidth
                        defaultValue=""
                        onChange={(e) => setNewPlan(e.target.value)}>
                        <MenuItem
                          value={{ isParentItem: true, id: 'referral-plans' }}>
                          ---- Referral Plans ---
                        </MenuItem>
                        {user.availableMembershipPlans?.referralPlans?.map(
                          (plan, i) => {
                            return (
                              plan.id !== user.membership_plan.id && (
                                <MenuItem value={plan} key={i}>
                                  {makeSingleRowPlanDetails(plan)}
                                </MenuItem>
                              )
                            )
                          }
                        )}
                        <br />
                        <br />
                        <MenuItem
                          value={{
                            isParentItem: true,
                            id: 'non-referral-plans',
                          }}>
                          ---- Non-Referral Plans ---
                        </MenuItem>
                        {user.availableMembershipPlans?.basePlans?.map(
                          (plan, i) => {
                            return (
                              plan.id !== user.membership_plan.id && (
                                <MenuItem value={plan} key={i}>
                                  {makeSingleRowPlanDetails(plan)}
                                </MenuItem>
                              )
                            )
                          }
                        )}
                      </MembershipSelect>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Plan Generation</TableCell>
                    <TableCell align="left">
                      {user.membership_plan?.internal_name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Plan Price</TableCell>
                    <TableCell>
                      {user.membership_plan.price
                        ? `$${user.membership_plan.price}/year`
                        : 'Free'}{' '}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Plan Permissions</TableCell>
                    <TableCell>
                      <div>Leads: {humanReadableLimit(leadsLimit)}</div>
                      <div>Codes: {humanReadableLimit(codesLimit)}</div>
                      <div>Teams: {humanReadableLimit(teamsLimit)}</div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Stripe Customer Id</TableCell>
                    {user.stripeCustomerId && (
                      <TableCell>
                        <a
                          href={`https://dashboard.stripe.com/customers/${user.stripeCustomerId}`}
                          target="_blank"
                          rel="noreferrer">
                          {user.stripeCustomerId}
                        </a>
                      </TableCell>
                    )}
                    <TableCell />
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <div>Trialing?</div>
                    </TableCell>
                    {user.membership.isTrialing ? (
                      <TableCell align="left">
                        Yes. This trial will end on{' '}
                        <b>{formatDate(user.membership.trialExpiryDate)}</b>
                      </TableCell>
                    ) : (
                      <TableCell>No</TableCell>
                    )}
                    {canEditTrialDays && (
                      <TableCell align="left">
                        <Typography>
                          How many days trial do you want to add? (note -
                          measures from today)
                        </Typography>
                        <TextField
                          type="number"
                          value={trialDaysRemaining}
                          inputProps={{ min: 0 }}
                          onChange={(e) => {
                            setTrialDaysRemaining(e.target.value)
                          }}
                        />
                        {trialDaysRemaining && (
                          <Typography>
                            Trial will now end on{' '}
                            <b>
                              {moment()
                                .add(trialDaysRemaining, 'days')
                                .format('MMM D, YYYY')}
                            </b>
                          </Typography>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>Membership Status</TableCell>
                    <TableCell align="left">{user.membership.status}</TableCell>
                    <TableCell align="left">
                      <MembershipSelect
                        value={membershipStatus}
                        onChange={(e) => setMembershipStatus(e.target.value)}
                        defaultValue="">
                        {user.availableMembershipStatusus?.map((status, i) => {
                          return (
                            <MenuItem value={status} key={i}>
                              {status}
                            </MenuItem>
                          )
                        })}
                      </MembershipSelect>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <ActionsContainer>
          <Button variant="contained" onClick={resetMembershipChanges}>
            Cancel Changes
          </Button>
          <Button variant="contained" onClick={handleUpdateMembership}>
            Confirm Membership Plan Changes
          </Button>
        </ActionsContainer>
      </Card>
      <Card>
        <CardHeader>
          <Typography variant="h5" component="h1">
            User Notes
          </Typography>
          <Button
            variant="contained"
            onClick={handleAddNote}
            disabled={userNotesLoading}>
            Add Note
          </Button>
        </CardHeader>
        <AdminUserNotes
          notes={user.notes}
          setActiveNote={setActiveNote}
          handleDeleteNote={handleDeleteNote}
          handleEditNote={handleEditNote}
        />
      </Card>
      <Dialog
        open={editReferralOpen}
        fullWidth
        onClose={() => setEditReferralOpen(false)}>
        <DialogTitle>Select a new referrer:</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Use this option to select a <b>speaker</b> as their referrer:
          </Typography>
          <Autocomplete
            options={referrerUsers}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select a speaker referrer ..."
              />
            )}
            onChange={(e, value) => {
              setSelectedReferrerUser(value)
            }}
          />
          <br />
          <br />
          <Typography variant="body1">
            Use this option to select <b>an organization</b> as the referrer:
          </Typography>
          <Autocomplete
            options={organizationReferralList}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select an organiztion referrer ..."
              />
            )}
            onChange={(e, value) => {
              setSelectedReferrerOrganization(value)
            }}
          />
          <br />
          <br />

          {/* Note: Can add more information about the referral commissions here if needed
                but thought that the complexity of that would be more harmful than useful for now */}
          <Notification variant="greyWarning" hideClose={true}>
            Updating this speakers referrer information will automatically make
            changes to any pending commissions, and/or create a commission if
            applicable.
          </Notification>
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={updateReferral} variant="contained">
            Update Referral Data
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={noteEditorOpen}
        onClose={() => setNoteEditorOpen(false)}
        fullWidth>
        <DialogTitle>Edit User Note</DialogTitle>
        <DialogContent>
          <ReactQuill
            theme="snow"
            value={editorValue}
            onChange={setEditorValue}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditor}>Cancel</Button>
          <Button onClick={handleSaveNote}>Save</Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  )
}

export default ShowUserParent
