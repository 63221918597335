import { styled } from '@mui/system'

import { Notification } from '../../common/Notification'

import { ReactComponent as EyeIcon } from '../../../icons/eyeIcon_16x16.svg'
import { ReactComponent as EyeOffIcon } from '../../../icons/eyeOffIcon_16x16.svg'

const DisabledNotification = styled('div')({
  svg: {
    margin: '0px 2px',
    verticalAlign: 'middle',
  },
})

const StyledDisabledIcon = styled(EyeOffIcon)(({ theme }) => ({
  path: {
    stroke: theme.palette.error.main,
  },
}))

const AlertText = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
}))

export const FirstTimeToggleEnabledNotification = () => (
  <Notification
    variant="blueWarningFilled"
    hideIcon={true}
    hideClose={true}
    maxWidth={true}>
    <DisabledNotification>
      Click on the <EyeIcon /> icon to toggle to <StyledDisabledIcon /> when you
      want to skip asking your audience a question.
    </DisabledNotification>
  </Notification>
)

const CodeBuilderQuestionDisabledNotification = ({ currentQuestion }) => {
  const notificationContent = () => {
    if (currentQuestion?.config?.templateIdentifier === 'CONTACT_INFO') {
      return (
        <DisabledNotification>
          <AlertText>
            Your audience will NOT see contact information collection questions.
          </AlertText>{' '}
          Toggle the <EyeIcon /> to visible show this question.
        </DisabledNotification>
      )
    }

    // All booking questions have grouping identifier of 0
    if (currentQuestion?.config?.groupingIdentifier === 0) {
      return (
        <DisabledNotification>
          <AlertText>
            Your audience will NOT see any booking questions.
          </AlertText>{' '}
          Toggle the <EyeIcon /> to visible show these questions.
        </DisabledNotification>
      )
    }

    return (
      <DisabledNotification>
        <AlertText>Your audience will NOT see this question.</AlertText> Toggle
        the <EyeIcon /> to visible show this question.
      </DisabledNotification>
    )
  }

  return (
    <Notification
      variant="subtleWarning"
      hideIcon={true}
      hideClose={true}
      maxWidth={true}>
      {notificationContent()}
    </Notification>
  )
}

export default CodeBuilderQuestionDisabledNotification
