import { useEffect, useState, useRef } from 'react'
import { styled } from '@mui/system'
import { Tooltip, useMediaQuery } from '@mui/material'

import CodeBuilderInputWrapper from './CodeBuilderInputWrapper'

const TextInput = styled('input', {
  shouldForwardProp: (prop) =>
    prop !== 'fontWeight' && prop !== 'error' && prop !== 'disableLeftPadding',
})(({ theme, fontWeight, error, disableLeftPadding }) => ({
  width: '100%',
  transition: '300ms all ease-in-out',
  fontFamily: 'Figtree, sans-serif',
  fontWeight: fontWeight || '400',
  fontSize: '0.875rem',
  border: 'none',
  position: 'relative',
  backgroundColor: 'transparent',
  color: error ? theme.palette.error.main : theme.palette.neutral.main,
  textOverflow: 'ellipsis',
  padding: '1px 10px',
  paddingLeft: disableLeftPadding ? '0' : '10px',
  height: '100%',
  minHeight: '48px',
  outline: 'none',
  borderTopRightRadius: theme.shape.borderRadius.xxs,
  borderBottomRightRadius: theme.shape.borderRadius.xxs,
  '&:focus': {
    outline: 'none',
  },
  '&::placeholder': {
    color: theme.palette.neutral.light,
    opacity: '50%',
  },
}))

const CodeBuilderInputText = ({
  value,
  inputValidation,
  inputDescription,
  marginTop,
  containerHeight,
  hint,
  placeholder,
  fontWeight,
  canEdit,
  editConfig,
  questionEnabled,
  handleOnChange,
  handleOnBlur,
  disabled,
  error,
  setError,
  disableLeftPadding,
  prefix,
  autoFocus,
  identifier,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const inputRef = useRef(null)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  useEffect(() => {
    if (value === '' || value !== inputValue) {
      setInputValue(value)
    }

    if (questionEnabled && canEdit && autoFocus) {
      setIsFocused(true)

      // NOTE: I don't love this timeout, but it's the only way I could get it to work
      // Need this timeout to allow the onBlur event to finish before setting focus
      // onBlur also runs on a timeout to allow the button click event to finish before sending the blur event
      setTimeout(() => {
        if (inputRef.current && document.activeElement !== inputRef.current) {
          inputRef.current.focus()
          inputRef.current.setSelectionRange(
            inputRef.current.value.length,
            inputRef.current.value.length
          )
        }
      }, 300)
    }

    if ((!canEdit || !questionEnabled) && isFocused) {
      setIsFocused(false)
    }

    if (inputValidation) {
      const validationError = inputValidation(value)
      if (validationError?.message) {
        setError(validationError)
      } else {
        setError(null)
      }
    }
  }, [value, autoFocus, questionEnabled, identifier])

  const handleChange = (e) => {
    if (!canEdit) {
      return
    }

    if (error) {
      setError(null)
    }

    const validationError = inputValidation && inputValidation(e.target.value)

    if (validationError?.message) {
      setError(validationError)
      if (!validationError?.allowContinue) {
        return
      }
    }

    setInputValue(e.target.value)
    handleOnChange(e.target.value)
  }

  const sendBlurEvent = async (newValue) => {
    // NOTE: I would like to skip the handleOnBlur if the value hasn't changed
    // but since we are modifying the value in the parent component, we can't compare
    // we could pass the original value to this component, but that seems like it might be overkill?

    if (error && !error.allowSubmit) {
      return
    }

    // if (canEdit && !error && newValue !== value) {
    if (canEdit) {
      setIsDisabled(true)
      await handleOnBlur(newValue)
      setIsDisabled(false)
    }
  }

  const handleBlur = async (e) => {
    setIsFocused(false)

    sendBlurEvent(inputValue)
  }

  const makeTooltipTitle = () => {
    if (!editConfig && canEdit) {
      return ''
    }

    // On mobile, if the user can't edit the content, show the input value as the tooltip
    // This is because the input value is potentially cut off and the user can't see the full value
    if (!editConfig && !canEdit && isMobile) {
      return inputValue
    }

    if (!editConfig && !canEdit) {
      return 'You cannot edit this content'
    }

    if (editConfig && !canEdit) {
      return 'Upgrade your plan to edit this option'
    }

    return ''
  }

  return (
    <CodeBuilderInputWrapper
      error={error}
      marginTop={marginTop}
      containerHeight={containerHeight}
      hint={hint}
      canEdit={canEdit}
      inputDescription={inputDescription}
      isFocused={isFocused}>
      {prefix && prefix}
      <Tooltip
        title={makeTooltipTitle()}
        placement="bottom"
        enterTouchDelay={0}
        leaveTouchDelay={3000}>
        <TextInput
          ref={inputRef}
          type="text"
          onChange={(e) => handleChange(e)}
          onBlur={(e) => handleBlur(e)}
          onFocus={() => setIsFocused(true)}
          value={inputValue}
          placeholder={placeholder}
          fontWeight={fontWeight}
          disabled={disabled || !canEdit || isDisabled}
          disableLeftPadding={disableLeftPadding}
        />
      </Tooltip>
    </CodeBuilderInputWrapper>
  )
}

export default CodeBuilderInputText
