import React from 'react'
import { ReactComponent as Emoji1 } from '../../icons/emoji-1.svg'
import { ReactComponent as Emoji2 } from '../../icons/emoji-2.svg'
import { ReactComponent as Emoji3 } from '../../icons/emoji-3.svg'
import { ReactComponent as Emoji4 } from '../../icons/emoji-4.svg'
import { ReactComponent as Emoji5 } from '../../icons/emoji-5.svg'
import { Typography } from '@mui/material'

const EngagementAnswers = ({ engagementStats }) => {
  // If there are labels for the engagement stats, use those to render
  // TODO:
  // Need to clean this up so all of them function off of labels instead of
  // just the event planner ones
  // This is super hackey, but need something to work for the event planner pilots
  const shouldUseLabels = !!engagementStats && engagementStats[0]?.label

  // TODO: should fetch these from the API
  const ENGAGEMENT_TYPES = [
    'Actionable',
    'Engaging',
    'Interactive',
    'Inspiring',
    'Relevant',
    'Educational',
    'Entertaining',
    'Fun',
    'Informative',
    'Powerful',
    'Practical',
  ]

  if (shouldUseLabels) {
    return engagementStats.map((stat, i) => {
      return (
        <EngagementAnswer
          key={i}
          answerValue={stat?.range_value}
          answerText={stat?.label}
        />
      )
    })
  } else {
    return ENGAGEMENT_TYPES.map((engagementType, i) => {
      const answer = engagementStats.find((eStat) => {
        const engagementTypeText = eStat.text_value || eStat.question_content
        return engagementTypeText
          .toLowerCase()
          ?.includes(engagementType.toLowerCase())
      })
      return (
        <EngagementAnswer
          key={i}
          answerValue={answer?.range_value}
          answerText={engagementType}
        />
      )
    })
  }
}

const EngagementAnswer = ({ answerValue, answerText }) => {
  const emojiList = [
    {
      icon: <Emoji1 />,
      value: '1',
    },
    {
      icon: <Emoji2 />,
      value: '2',
    },
    {
      icon: <Emoji3 />,
      value: '3',
    },
    {
      icon: <Emoji4 />,
      value: '4',
    },
    {
      icon: <Emoji5 />,
      value: '5',
    },
  ]

  const emoji = emojiList.find((e) => e.value === answerValue)

  if (!answerValue) {
    return <div />
  } else {
    return (
      <div className="submission-answer">
        {emoji?.icon}
        <Typography variant="body1" component="div">
          {answerText}
        </Typography>
      </div>
    )
  }
}

export default EngagementAnswers
