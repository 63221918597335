import { useEffect, useState, useContext } from 'react'
import { styled } from '@mui/system'
import { Dialog, Button, LinearProgress } from '@mui/material'
import { useHistory } from 'react-router-dom'

import Logout from '@mui/icons-material/Logout'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import { OnboardingContext } from '../onboardingContext'
import { addAlpha } from '../../../utils/color'
import { device } from '../../../responsive'

import SurveyOffersOnboardingStepOne from './SurveyOffersOnboardingStepOne'
import SurveyOffersOnboardingStepTwo from './SurveyOffersOnboardingStepTwo'
import SurveyOffersOnboardingStepThree from './SurveyOffersOnboardingStepThree'
import SurveyOffersOnboardingStepFive from './SurveyOffersOnboardingStepFive'
import SurveyOffersOnboardingStepSix from './SurveyOffersOnboardingStepSix'
import SurveyOffersOnboardingStepSeven from './SurveyOffersOnboardingStepSeven'
import SurveyOffersOnboardingStepEight from './SurveyOffersOnboardingStepEight'
import SurveyOffersOnboardingStepNine from './SurveyOffersOnboardingStepNine'
import SurveyOffersForm from '../../dashboard/SurveyOffersForm'

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'hideBackground',
})(({ theme, hideBackground }) => ({
  background: addAlpha(theme.palette.tertiary.main, 0.98),
  '& .MuiDialog-paper': {
    overflow: 'visible',
    background: hideBackground ? 'none' : theme.palette.base.white,
    boxShadow: hideBackground ? 'none' : theme.shadows[24],
  },
}))

const LogOutButton = styled(Button)({
  position: 'fixed',
  top: '20px',
  right: '20px',
  span: {
    marginLeft: '5px',
  },
  [`@media ${device.mobile}`]: {
    top: '5px',
    right: '5px',
    span: {
      fontSize: '0.8rem',
    },
  },
})

const StepProgressContainer = styled('div')({
  position: 'fixed',
  bottom: '40px',
  right: '40px',
})

const SurveyOffersOnboardingParent = ({
  user,
  surveyLoading,
  offers,
  setOffers,
  setOffer,
  offer,
  defaultEmojiKeywords,
  setVideoId,
  setVideoModalOpen,
  surveyOffersFormModalSize,
  setSurveyOffersFormModalSize,
  surveyOffersFormModalFullWidth,
  setSurveyOffersFormModalFullWidth,
  surveyLink,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const { setNotification, setProfileAttribute, logoutUser } = useContext(
    AuthenticationContext
  )
  const { submitSurveyOffersOnboardingInfoData } = useContext(OnboardingContext)
  const history = useHistory()

  const onboardingInfo = user?.onboarding_info
  const last_step_number = 9

  useEffect(() => {
    const showModal = () => {
      return (
        onboardingInfo &&
        !onboardingInfo?.survey_offers?.completed &&
        window.innerWidth > 700
      )
    }
    if (showModal() && !surveyLoading) {
      // if offers length is greater than 1, a survey offer has already been created
      // this is the case when a user has already created a survey offer in the onboarding flow,
      // abandoned the onboarding flow and then returned to it, or if they refresh the page
      // setOffer to the first offer in the array where the code is not TALK
      if (offers.length > 1) {
        setOffer(offers.filter((offer) => offer.code !== 'TALK')[0])
        setCode(offers.filter((offer) => offer.code !== 'TALK')[0].code)
      }

      setModalOpen(true)
    }
  }, [user, surveyLoading])

  useEffect(() => {
    const currentStep = onboardingInfo?.survey_offers?.last_step

    if (
      currentStep &&
      currentStep === 4 &&
      surveyOffersFormModalSize === 'lg'
    ) {
      setSurveyOffersFormModalSize('md')
      setSurveyOffersFormModalFullWidth(true)
    } else if (currentStep && surveyOffersFormModalSize === 'md') {
      setSurveyOffersFormModalSize('lg')
      setSurveyOffersFormModalFullWidth(false)
    }
  }, [onboardingInfo])

  const isOnLastStep = () => {
    return onboardingInfo?.survey_offers?.last_step === last_step_number
  }

  const advanceLastStep = async (stepIncrement = 1) => {
    const params = {
      onboarding_info: {
        survey_offers_onboarding_info: {
          completed: isOnLastStep(),
          last_step: isOnLastStep()
            ? onboardingInfo?.survey_offers?.last_step
            : onboardingInfo?.survey_offers?.last_step + stepIncrement,
        },
      },
    }

    try {
      setLoading(true)

      const res = await submitSurveyOffersOnboardingInfoData(params)

      if (res.errors) {
        setLoading(false)
        throw res.errors
      } else {
        setProfileAttribute('onboarding_info', {
          ...onboardingInfo,
          survey_offers: {
            completed: isOnLastStep(),
            last_step: isOnLastStep()
              ? onboardingInfo?.survey_offers?.last_step
              : onboardingInfo?.survey_offers?.last_step + stepIncrement,
          },
        })
        setLoading(false)
      }

      if (isOnLastStep()) {
        setModalOpen(false)
        history.push('/codes/edit')
      }
    } catch (error) {
      setNotification(error, 'error')
    }
  }

  const skipOnboarding = async () => {
    const params = {
      onboarding_info: {
        survey_offers_onboarding_info: {
          completed: true,
          last_step: last_step_number,
        },
      },
    }

    try {
      setLoading(true)

      const res = await submitSurveyOffersOnboardingInfoData(params)

      if (res.errors) {
        setLoading(false)
        throw res.errors
      } else {
        setProfileAttribute('onboarding_info', {
          ...onboardingInfo,
          survey_offers: {
            completed: true,
            last_step: last_step_number,
          },
        })
        setLoading(false)
      }

      setModalOpen(false)
    } catch (error) {
      setNotification(error, 'error')
    }
  }

  const hideBackground = () => {
    // NOTE: this needs to be set for the SurveyOffersForm otherwise the form will blend into the background
    return onboardingInfo?.survey_offers?.last_step !== 4
  }

  const renderStepContent = () => {
    const currentStep = onboardingInfo?.survey_offers?.last_step
    switch (currentStep) {
      case 1:
        return (
          <SurveyOffersOnboardingStepOne
            user={user}
            advanceLastStep={advanceLastStep}
            skipOnboarding={skipOnboarding}
            loading={loading}
          />
        )
      case 2:
        return (
          <SurveyOffersOnboardingStepTwo
            user={user}
            advanceLastStep={advanceLastStep}
            loading={loading}
          />
        )
      case 3:
        return (
          <SurveyOffersOnboardingStepThree
            user={user}
            advanceLastStep={advanceLastStep}
            loading={loading}
          />
        )
      case 4:
        return (
          <SurveyOffersForm
            offers={offers}
            setOffers={setOffers}
            setOffer={setOffer}
            offer={offer}
            defaultEmojiKeywords={defaultEmojiKeywords}
            setVideoId={setVideoId}
            setVideoModalOpen={setVideoModalOpen}
            setSurveyOffersFormModalSize={setSurveyOffersFormModalSize}
            setSurveyOffersFormModalFullWidth={
              setSurveyOffersFormModalFullWidth
            }
            surveyLink={surveyLink}
            isOnboarding={true}
            advanceLastStep={advanceLastStep}
            setCode={setCode}
          />
        )
      case 5:
        return (
          <SurveyOffersOnboardingStepFive
            user={user}
            advanceLastStep={advanceLastStep}
            loading={loading}
            code={code}
            offers={offers}
          />
        )
      case 6:
        return (
          <SurveyOffersOnboardingStepSix
            user={user}
            advanceLastStep={advanceLastStep}
            loading={loading}
            code={code}
          />
        )
      case 7:
        return (
          <SurveyOffersOnboardingStepSeven
            user={user}
            advanceLastStep={advanceLastStep}
            loading={loading}
            shortLink={user.shortlink}
            code={code}
            setLoading={setLoading}
          />
        )
      case 8:
        return (
          <SurveyOffersOnboardingStepEight
            advanceLastStep={advanceLastStep}
            loading={loading}
            user={user}
            code={code}
          />
        )
      case 9:
        return (
          <SurveyOffersOnboardingStepNine
            advanceLastStep={advanceLastStep}
            loading={loading}
            user={user}
            code={code}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <StyledDialog
      fullWidth={surveyOffersFormModalFullWidth}
      maxWidth={surveyOffersFormModalSize}
      hideBackground={hideBackground()}
      scroll="body"
      open={modalOpen}>
      {renderStepContent()}
      <LogOutButton
        varient="text"
        aria-label="log out"
        onClick={logoutUser}
        color="secondary">
        <Logout fontSize="small" />
        <span>Log Out</span>
      </LogOutButton>
      <StepProgressContainer>
        <LinearProgress
          variant="determinate"
          color="secondary"
          value={
            (onboardingInfo?.survey_offers?.last_step / last_step_number) * 100
          }
          sx={{ width: '200px', height: '10px', borderRadius: '5px' }}
        />
      </StepProgressContainer>
    </StyledDialog>
  )
}

export default SurveyOffersOnboardingParent
