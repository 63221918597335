import { useContext, useState } from 'react'
import { styled } from '@mui/system'
import { Button, Typography } from '@mui/material'

import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'
import {
  usePermissionHelper,
  OFFER_CODE_LIMIT,
} from '../../utils/permission_helper'
import { GroupContext } from '../groups/groupContext'

import SurveyOffersList from './SurveyOffersList'
import ConfirmDialog from '../common/ConfirmDialog'

const OffersWrapper = styled('div')({
  maxHeight: '216px',
  overflowY: 'scroll',
  marginBottom: '10px',
})

const OffersHeader = styled('div')({
  position: 'sticky',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '2px 0',
  borderTop: '1px solid #d3d3d3',
})

const NewOfferButton = styled(Button)({
  color: 'white',
  width: '50%',
  marginTop: '10px',
})

const SurveyOffersParent = ({
  offers,
  setOffers,
  handleEdit,
  handleOpenCreateOfferForm,
  events,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [deleteOfferId, setDeleteOfferId] = useState(null)

  const { setNotification, toggleUpgradeModal } = useContext(
    AuthenticationContext
  )
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)
  const { findPermission } = usePermissionHelper()

  const { limit, unlimitedUsage } = findPermission(OFFER_CODE_LIMIT)

  const handleConfirmDelete = async () => {
    try {
      let res
      if (selectedGroup) {
        res = await Api.deleteGroupSurveyOffer(deleteOfferId, selectedGroup.id)
      } else {
        throw new Error('Deprecated')
      }
      if (!res.errors) {
        setOffers(offers?.filter((offer) => offer.id !== deleteOfferId))
        setDeleteOfferId(null)
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
      setDeleteOfferId(null)
    }
  }

  const handleDelete = async (offerId) => {
    setDeleteOfferId(offerId)
    setConfirmOpen(true)
  }

  const SurveyOffersHeader = () => (
    <OffersHeader className="survey-offers__offer header">
      <Typography component="div" variant="body1" id="offer-code">
        <b>Code</b>
      </Typography>
      <Typography component="div" variant="body1" className="offer-name">
        <b>Code Description</b>
      </Typography>
      <div></div>
    </OffersHeader>
  )

  const deleteOfferCode = () => {
    if (!deleteOfferId) return null
    const offer = offers.find((offer) => offer.id === deleteOfferId)
    return offer?.code
  }

  return (
    <div className="survey-offers">
      <Typography component="div" variant="h6">
        <b>
          You have{' '}
          <span style={{ color: 'orange' }}>
            {offers?.length ? offers?.length - 1 : '0'}
          </span>{' '}
          active Talkadot codes
        </b>
      </Typography>
      {!unlimitedUsage && (
        <Typography component="div" variant="body2">
          Limit: <span style={{ color: 'orange' }}>{limit}</span>{' '}
          <span
            className="survey-offers__upgrade-link"
            onClick={() =>
              toggleUpgradeModal(
                true,
                'Upgrade to get more codes!',
                OFFER_CODE_LIMIT
              )
            }>
            (upgrade to increase limit)
          </span>
        </Typography>
      )}
      <SurveyOffersHeader />
      <OffersWrapper>
        <SurveyOffersList
          offers={offers}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          events={events}
        />
      </OffersWrapper>
      {(!offers || offers?.length < 1) && (
        <div>
          Adding an offer increases your chance of getting feedback and leads:
        </div>
      )}
      <NewOfferButton onClick={handleOpenCreateOfferForm} variant="contained">
        {window.innerWidth <= 480 ? 'Add Code' : 'Add Another Talkadot Code'}
      </NewOfferButton>
      <ConfirmDialog
        title="Are you sure you want to delete this survey code"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirmDelete}
        onCancel={() => setDeleteOfferId(null)}
        buttonColor="black"
        buttonText="Delete Code"
        manualConfirm={true}
        confirmText="DELETE CODE">
        <>
          You will no longer be able to use survey code{' '}
          <strong>{deleteOfferCode()}</strong> to collect feedback.
        </>
      </ConfirmDialog>
    </div>
  )
}

export default SurveyOffersParent
