import { useState } from 'react'
import { styled } from '@mui/system'
import {
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

import { ReactComponent as EllipisIcon } from '../../icons/ellipsisIcon.svg'
import { ReactComponent as EditIcon } from '../../icons/editIcon_16x16.svg'
import { ReactComponent as DeleteIcon } from '../../icons/trash2Icon_16x16.svg'

import { formatDateWithTimeZone } from '../common/helpers'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

const Note = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius.sm,
  position: 'relative',
}))

const Footer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
}))

const SignatureContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}))

const AdminSignature = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const DropdownButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(0.5),
  top: theme.spacing(0.5),
}))

const AdminUserNotes = ({
  notes,
  setActiveNote,
  handleEditNote,
  handleDeleteNote,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpenDropdown = (e, note) => {
    setActiveNote(note)
    setAnchorEl(e.currentTarget)
  }

  const handleCloseDropdown = () => {
    setAnchorEl(null)
  }

  const editNote = () => {
    handleCloseDropdown()
    handleEditNote()
  }

  const deleteNote = async () => {
    handleCloseDropdown()
    handleDeleteNote()
  }

  const menuItems = [
    {
      text: 'Edit',
      onClick: () => editNote(),
      icon: <EditIcon />,
    },
    {
      text: 'Delete',
      onClick: () => deleteNote(),
      icon: <DeleteIcon />,
    },
  ]

  const renderMenuItem = ({ text, onClick, icon, loading, index }) => {
    return (
      <MenuItem key={index} onClick={onClick} disabled={loading}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={text} />
      </MenuItem>
    )
  }

  const renderActionsDropdown = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {menuItems.map((item, index) => {
          return renderMenuItem({ ...item, index })
        })}
      </Menu>
    )
  }

  return (
    <Container>
      {notes.map((note) => (
        <Note key={note.id}>
          <DropdownButton onClick={(e) => handleOpenDropdown(e, note)}>
            <EllipisIcon />
          </DropdownButton>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{ __html: note.content }}
          />
          <Footer>
            <SignatureContainer>
              <AdminSignature>
                <Typography variant="caption">
                  Created by: {note.createdBy}
                </Typography>
                <Typography variant="caption">
                  {formatDateWithTimeZone(note.createdAt)}
                </Typography>
              </AdminSignature>
              {note.updatedAt > note.createdAt && (
                <AdminSignature>
                  <Typography variant="caption">
                    Last Updated by: {note.lastEditedBy}
                  </Typography>
                  <Typography variant="caption">
                    {formatDateWithTimeZone(note.updatedAt)}
                  </Typography>
                </AdminSignature>
              )}
            </SignatureContainer>
          </Footer>
        </Note>
      ))}
      {renderActionsDropdown()}
    </Container>
  )
}

export default AdminUserNotes
