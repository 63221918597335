import { useState, useRef } from 'react'
import { styled } from '@mui/system'
import { Drawer, List } from '@mui/material'

import NavItems from './NavItems'

import { upperNavHeightMobile } from '../../styles/layoutNavConstants'

const DrawerHeaderLayout = styled('div')(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(1),
  minHeight: upperNavHeightMobile,
}))

const BurgerMenu = ({ open, handleAutoOpenDrawer, closeDrawer }) => {
  const [isScrolling, setIsScrolling] = useState(false)
  const touchStartY = useRef(0)

  return (
    <Drawer open={open} onClose={closeDrawer} variant="temporary">
      <DrawerHeaderLayout />
      <List>
        <NavItems
          open={open}
          closeDrawer={closeDrawer}
          isScrolling={isScrolling}
          setIsScrolling={setIsScrolling}
          touchStartY={touchStartY}
          handleAutoOpenDrawer={handleAutoOpenDrawer}
        />
      </List>
    </Drawer>
  )
}

export default BurgerMenu
