import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import { styled } from '@mui/system'

const CodesNoticeContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.lightest,
  padding: '12px 20px',
  maxWidth: '640px',
  margin: '10px auto',
  borderRadius: '8px',
}))

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  fontWeight: 'bold',
  color: theme.palette.secondary.main,
  cursor: 'pointer',
}))

const CreateCodeAlert = () => {
  return (
    <CodesNoticeContainer>
      <Typography variant="paragraph03">
        👉 Codes are how you collect feedback.{' '}
        <StyledLink to="/codes/edit">Go make your first code!</StyledLink>
      </Typography>
    </CodesNoticeContainer>
  )
}

export default CreateCodeAlert
