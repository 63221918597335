import { styled } from '@mui/system'
import { Breadcrumbs, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import { ReactComponent as DashboardIcon } from '../../icons/navIcons/newIcon.svg'
import { ReactComponent as CaretIcon } from '../../icons/navIcons/breadCrumbCaret.svg'

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
  p: {
    color: theme.palette.text.navText,
  },
  a: {
    textDecoration: 'none',
    color: theme.palette.text.navText,
  },
}))

const StyledDashboardIcon = styled(DashboardIcon)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  path: {
    fill: theme.palette.neutral.semiDark,
  },
}))

const CaretDownIcon = styled(CaretIcon)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(1),
}))

const NavBreadCrumbs = ({ breadCrumbs }) => {
  return (
    <StyledBreadcrumbs aria-label="breadcrumb" separator={<CaretDownIcon />}>
      <Link to="/dashboard">
        <StyledDashboardIcon />
      </Link>
      {breadCrumbs.map((breadCrumb, index) => {
        if (breadCrumb.link) {
          return (
            <Link key={index} to={breadCrumb.link}>
              {breadCrumb.text}
            </Link>
          )
        }

        return (
          <Typography
            key={index}
            sx={(theme) => ({
              fontWeight: index === breadCrumbs.length - 1 ? '600' : '400',
              color:
                index === breadCrumbs.length - 1
                  ? theme.palette.text.inputText
                  : theme.palette.text.navText,
            })}>
            {breadCrumb.text}
          </Typography>
        )
      })}
    </StyledBreadcrumbs>
  )
}

export default NavBreadCrumbs
