import { useContext, Fragment } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { styled } from '@mui/system'
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { GroupContext } from '../groups/groupContext'
import { AuthenticationContext } from '../authentication/authenticationContext'

import { usePermissionHelper } from '../../utils/permission_helper'
import { useIsChapterPage } from '../../utils/pathHelper'

import TableChip from '../common/TableChip'

import { ReactComponent as NewIcon } from '../../icons/navIcons/newIcon.svg'
// import { ReactComponent as DashboardIcon } from '../../icons/navIcons/dashboardIcon.svg'
import { ReactComponent as TalksIcon } from '../../icons/navIcons/talksIcon.svg'
import { ReactComponent as CodesIcon } from '../../icons/navIcons/codesIcon.svg'
import { ReactComponent as LeadsIcon } from '../../icons/navIcons/leadsIcon.svg'
import { ReactComponent as ResourcesIcon } from '../../icons/navIcons/resourcesIcon.svg'
import { ReactComponent as CommunityIcon } from '../../icons/navIcons/communityIcon.svg'
import { ReactComponent as GiftIcon } from '../../icons/giftIcon.svg'
import { ReactComponent as GroupEventsIcon } from '../../icons/group-events.svg'
import { ReactComponent as SettingsIcon } from '../../icons/navIcons/settingsIcon.svg'
import { ReactComponent as ExternalLinkIcon } from '../../icons/externalLinkIcon_16x16.svg'
import { ReactComponent as HeadphoneIcon } from '../../icons/headphoneIcon.svg'

const MenuItemIconContainer = styled('div')({
  display: 'flex',
  alightItems: 'center',
  position: 'relative',
})

const MultiContentWrapper = styled('span')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
})

const BadgeIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '-6px',
  right: '-7px',
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  border: `0.75px solid ${theme.palette.base.white}`,
  backgroundColor: theme.palette.secondary.main,
}))

const StyledGiftIcon = styled(GiftIcon)(({ theme }) => ({
  stroke: theme.palette.neutral.dark,
  width: '22px',
  height: '22px',
}))

const MenuItem = styled(ListItemButton, {
  shouldForwardProp: (prop) =>
    prop !== 'selected' && prop !== 'open' && prop !== 'spacer',
})(({ theme, open, selected, spacer }) => ({
  minHeight: 48,
  justifyContent: open ? 'initial' : 'center',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  backgroundColor: selected && theme.palette.secondary.main,
  borderRadius: theme.shape.borderRadius.xs,
  '&:hover': {
    backgroundColor: selected && theme.palette.secondary.light,
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: spacer ? 24 : 48,
    padding: spacer ? 0 : '',
  },
}))

const IconWrapper = styled(ListItemIcon, {
  shouldForwardProp: (prop) =>
    prop !== 'selected' && prop !== 'open' && prop !== 'type',
})(({ theme, open, selected, type }) => ({
  minWidth: 0,
  marginRight: open ? theme.spacing(1.5) : 0,
  justifyContent: 'center',
  '& svg path': {
    fill:
      type === 'Talks' || type === 'Settings' || type === 'Refer & Earn'
        ? ''
        : selected
        ? theme.palette.base.white
        : '',
    stroke:
      type === 'Talks' || type === 'Settings' || type === 'Refer & Earn'
        ? selected
          ? theme.palette.base.white
          : ''
        : '',
  },
}))

const MenuItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'open',
})(({ theme, open, selected }) => ({
  display: !open && 'none',
  margin: 0,
  p: {
    fontWeight: 600,
    color: selected && theme.palette.base.white,
  },
}))

const SubMenuContainer = styled('div')(({ theme, selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: theme.spacing(3),
  marginTop: selected ? theme.spacing(1) : 0,
  height: selected ? 'auto' : 0,
}))

const SubMenuItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(3),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.surface.subtle,
  },
  transition: theme.transitions.create('background-color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
}))

const SubMenuItemIcon = styled('div')(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  border: selected
    ? `1px solid ${theme.palette.neutral.extraLight}`
    : `2px solid ${theme.palette.neutral.extraLight}`,
  backgroundColor: selected ? theme.palette.secondary.main : 'transparent',
}))

const SubMenuItemText = styled(Typography)(({ theme, selected }) => ({
  marginLeft: theme.spacing(1),
  color: selected ? theme.palette.secondary.main : theme.palette.text.navText,
}))

const NavItems = ({
  open,
  closeDrawer,
  isScrolling,
  setIsScrolling,
  touchStartY,
  handleAutoOpenDrawer,
}) => {
  const location = useLocation()
  const history = useHistory()
  const { canAccessTeams } = usePermissionHelper()
  const pathName = location.pathname
  const isChapterPage = useIsChapterPage()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)
  const {
    updateCounter,
    authData: {
      user,
      user: {
        counters: {
          unviewedEvents,
          unviewedLeads,
          unviewedCommunityNotifications,
        },
      },
    },
  } = useContext(AuthenticationContext)

  const GROUP_NAV_ITEMS = [
    {
      icon: <NewIcon />,
      content: isChapterPage ? 'My Chapters' : 'Dashboard',
      isSelected: pathName === '/dashboard' || pathName === '/groups',
      handleClick: isChapterPage
        ? () => history.push('/groups')
        : () => history.push('/dashboard'),
      closeOnClick: true,
    },
    ...(selectedGroup
      ? [
          {
            icon: <GroupEventsIcon />,
            content: 'Events',
            isSelected: pathName.includes('events'),
            disabled: !selectedGroup,
            // NOTE: this is kind of redundant since we're already checking for selectedGroup above
            // but I'm leaving it here for now in case we want to show the menu item regardless of whether a group is selected
            handleClick: () =>
              selectedGroup &&
              history.push(`/groups/${selectedGroup?.id}/events`),
          },
        ]
      : []),
    {
      icon: <></>,
      content: '',
      isSelected: false,
      disabled: true,
    },
    {
      icon: <SettingsIcon />,
      content: 'Settings',
      isSelected: pathName.includes('account'),
      handleClick: () => history.push('/account/settings'),
    },
    // {
    //   key: 2,
    //   icon: <GroupMembersIcon />,
    //   content: 'Chapters',
    //   isSelected: pathName.includes('members'),
    //   link: `/members`,
    // },
  ]

  const SPEAKER_NAV_ITEMS = [
    {
      icon: <NewIcon />,
      content: 'Home',
      isSelected: pathName === '/dashboard' || pathName === '/',
      handleClick: () => history.push('/dashboard'),
      closeOnClick: true,
    },
    {
      icon: <></>,
      content: '',
      isSelected: false,
      disabled: true,
      spacer: true,
    },
    {
      icon:
        !open && !selectedGroup && unviewedEvents > 0 ? (
          <MenuItemIconContainer>
            <TalksIcon />
            <BadgeIcon />
          </MenuItemIconContainer>
        ) : (
          <TalksIcon />
        ),
      identifier: 'Talks',
      content:
        !selectedGroup && unviewedEvents > 0 ? (
          <MultiContentWrapper>
            <span>Talks</span>
            <TableChip
              variant={'body1'}
              size={'medium'}
              fontWeight={'semiBold'}
              color={'lightBlue'}
              text={unviewedEvents}
            />
          </MultiContentWrapper>
        ) : (
          'Talks'
        ),
      isSelected: pathName.includes('talks'),
      handleClick: () => history.push('/talks'),
      hidden: user?.isEventPlanner,
      closeOnClick: true,
    },
    {
      icon: <CodesIcon />,
      content: 'Codes',
      isSelected: pathName.includes('codes'),
      handleClick: () => history.push('/codes'),
      hidden: user?.isEventPlanner,
      closeOnClick: false,
      subMenuItems: [
        {
          icon: <></>,
          content: 'Your Codes',
          isSelected: pathName === '/codes',
          handleClick: () => history.push('/codes'),
          closeOnClick: true,
        },
        {
          icon: <></>,
          content: 'Add a Code',
          isSelected: pathName.includes('codes/edit'),
          handleClick: () => history.push('/codes/edit'),
          closeOnClick: true,
        },
      ],
    },
    {
      icon:
        !open && !selectedGroup && unviewedLeads > 0 ? (
          <MenuItemIconContainer>
            <LeadsIcon />
            <BadgeIcon />
          </MenuItemIconContainer>
        ) : (
          <LeadsIcon />
        ),
      content:
        !selectedGroup && unviewedLeads > 0 ? (
          <MultiContentWrapper>
            <span>Leads</span>
            <TableChip
              variant={'body1'}
              size={'medium'}
              fontWeight={'semiBold'}
              color={'lightBlue'}
              text={unviewedLeads}
            />
          </MultiContentWrapper>
        ) : (
          'Leads'
        ),
      isSelected: pathName.includes('leads'),
      handleClick: () => history.push('/leads'),
      closeOnClick: false,
      subMenuItems: [
        {
          icon: <></>,
          content: 'My Leads',
          isSelected: pathName === '/leads',
          handleClick: () => history.push('/leads'),
          closeOnClick: true,
        },
        {
          icon: <></>,
          content: 'Leads followup',
          isSelected: pathName.includes('leads/follow-up'),
          handleClick: () => history.push('/leads/follow-up'),
          closeOnClick: true,
        },
        {
          icon: <></>,
          content: 'Integrations',
          isSelected: pathName.includes('leads/integrations'),
          handleClick: () => history.push('/leads/integrations'),
          closeOnClick: true,
        },
      ],
    },
    {
      icon: <></>,
      content: '',
      isSelected: false,
      disabled: true,
      spacer: true,
    },
    {
      icon: <ResourcesIcon />,
      content: (
        <MultiContentWrapper>
          <span>Resources</span>
          <ExternalLinkIcon />
        </MultiContentWrapper>
      ),
      isSelected: pathName.includes('resources'),
      // Take them to resources even if they aren't a user in circles, since
      // the resource pages are public and dont require an account
      handleClick: () =>
        window.open('https://talk.talkadot.com/c/resources', '_blank'),
      closeOnClick: true,
    },
    {
      icon:
        !open && unviewedCommunityNotifications > 0 ? (
          <MenuItemIconContainer>
            <CommunityIcon />
            <BadgeIcon />
          </MenuItemIconContainer>
        ) : (
          <CommunityIcon />
        ),
      content:
        unviewedCommunityNotifications > 0 ? (
          <MultiContentWrapper>
            <span>Community</span>
            <TableChip
              variant={'body1'}
              size={'medium'}
              fontWeight={'semiBold'}
              color={'lightBlue'}
              text={unviewedCommunityNotifications}
            />
          </MultiContentWrapper>
        ) : (
          <MultiContentWrapper>
            <span>Community</span>
            <ExternalLinkIcon />
          </MultiContentWrapper>
        ),
      isSelected: pathName.includes('community'),
      handleClick: () => {
        updateCounter({ unviewedCommunityNotifications: 0 })
        window.open('https://talk.talkadot.com/sign_in', '_blank')
      },
      closeOnClick: true,
    },
    {
      icon: <HeadphoneIcon />,
      content: (
        <MultiContentWrapper>
          <span>Onboarding</span>
          <ExternalLinkIcon />
        </MultiContentWrapper>
      ),
      isSelected: pathName.includes('onboarding'),
      handleClick: () =>
        window.open(
          'https://talk.talkadot.com/c/onboardings/?sort=asc',
          '_blank'
        ),
      closeOnClick: true,
    },
    {
      icon: <StyledGiftIcon />,
      identifier: 'Refer & Earn',
      content: 'Refer & Earn',
      isSelected: pathName.includes('refer-and-earn'),
      handleClick: () => history.push('/refer-and-earn'),
      closeOnClick: true,
    },
    {
      icon: <></>,
      content: '',
      isSelected: false,
      disabled: true,
      spacer: true,
    },
    {
      icon: <SettingsIcon />,
      identifier: 'Settings',
      content: 'Settings',
      isSelected: pathName.includes('account'),
      handleClick: () => history.push('/account/settings'),
      closeOnClick: false,
      subMenuItems: [
        {
          icon: <></>,
          content: 'Account',
          isSelected: pathName.includes('account/settings'),
          handleClick: () => history.push('/account/settings'),
          closeOnClick: true,
        },
        {
          icon: <></>,
          content: 'Plan & Billing',
          isSelected: pathName.includes('account/billing'),
          handleClick: () => history.push('/account/billing'),
          closeOnClick: true,
        },
      ].concat(
        canAccessTeams()
          ? [
              {
                icon: <></>,
                content: 'Team Management',
                isSelected: pathName.includes('account/team'),
                handleClick: () => history.push('/account/team'),
                closeOnClick: true,
              },
            ]
          : []
      ),
    },
  ]

  const handleOnTouch = ({ handleClick, closeOnClick }) => {
    handleClick()

    if (open && closeOnClick & isMobile) {
      closeDrawer()
    }
  }

  const handleTouchStart = (e) => {
    touchStartY.current = e.touches[0].clientY
    setIsScrolling(false)
  }

  const handleTouchMove = (e) => {
    const touchCurrentY = e.touches[0].clientY
    const deltaY = Math.abs(touchCurrentY - touchStartY.current)
    if (deltaY > 5) {
      // Adjust this threshold as needed
      setIsScrolling(true)
    }
  }

  const handleTouchEnd = ({ handleClick, closeOnClick }) => {
    if (!isScrolling) {
      handleOnTouch({ handleClick, closeOnClick })
    }
    setIsScrolling(false)
  }

  const renderNavItems = () => {
    const navItems =
      pathName.includes('groups') && !pathName.includes('event-planner')
        ? GROUP_NAV_ITEMS
        : SPEAKER_NAV_ITEMS

    return navItems.map(
      (
        {
          icon,
          identifier,
          content,
          isSelected,
          disabled,
          handleClick,
          subMenuItems,
          spacer,
          hidden,
          closeOnClick,
        },
        i
      ) => {
        return hidden ? (
          <Fragment key={`nav-item-${i}`}></Fragment>
        ) : (
          <Fragment key={`nav-item-${i}`}>
            <ListItem key={`nav-item-${i}`} disablePadding>
              <MenuItem
                selected={isSelected}
                open={open}
                disabled={disabled}
                spacer={spacer}
                onClick={() => handleClick()}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={() => handleTouchEnd({ handleClick, closeOnClick })}
                onPointerEnter={handleAutoOpenDrawer}>
                <IconWrapper
                  open={open}
                  selected={isSelected}
                  type={identifier}>
                  {icon}
                </IconWrapper>
                <MenuItemText
                  primary={content}
                  open={open}
                  selected={isSelected}
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </MenuItem>
            </ListItem>
            {open && subMenuItems && (
              <SubMenuContainer selected={isSelected}>
                {isSelected &&
                  subMenuItems.map(
                    ({ content, isSelected, handleClick, closeOnClick }, j) => (
                      <SubMenuItem
                        key={`sub-menu-${j}`}
                        onClick={() => handleClick()}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={() =>
                          handleTouchEnd({
                            isSelected,
                            handleClick,
                            closeOnClick,
                          })
                        }>
                        <SubMenuItemIcon selected={isSelected} />
                        <SubMenuItemText selected={isSelected}>
                          {content}
                        </SubMenuItemText>
                      </SubMenuItem>
                    )
                  )}
              </SubMenuContainer>
            )}
          </Fragment>
        )
      }
    )
  }

  return renderNavItems()
}

export default NavItems
