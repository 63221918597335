import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/system'
import { Dialog, DialogContent } from '@mui/material'
import YouTube from 'react-youtube'
import { isMobileOnly } from 'react-device-detect'

import Api from '../../../services/api'
import { AuthenticationContext } from '../../authentication/authenticationContext'

import {
  initialCodesState,
  initialOfferState,
} from '../../../constants/codes/initialOfferStates'
import SurveyOffersOnboardingModalParent from './SurveyOffersOnboardingModalParent'

const videoOpts = {
  playerVars: {
    autoplay: 1,
  },
  width: '100%',
  height: '100%',
}

const VideoDialogContent = styled(DialogContent)({
  minHeight: '600px',
  padding: 0,
})

const VideoContainer = styled('div')({
  height: '600px',
})

const SurveyOffersOnboardingPageParent = () => {
  const [surveyLoading, setSurveyLoading] = useState(true)
  const [offers, setOffers] = useState(initialCodesState)
  const [offer, setOffer] = useState(initialOfferState)
  const [surveyLink, setSurveyLink] = useState(null)
  const [defaultEmojiKeywords, setDefaultEmojiKeywords] = useState([])
  const [videoId, setVideoId] = useState(null)
  const [videoModalOpen, setVideoModalOpen] = useState(false)
  const [surveyOffersFormModalSize, setSurveyOffersFormModalSize] =
    useState('md')
  const [surveyOffersFormModalFullWidth, setSurveyOffersFormModalFullWidth] =
    useState(true)
  const {
    authData: { user },
    setNotification,
  } = useContext(AuthenticationContext)

  const history = useHistory()

  useEffect(() => {
    const loadSurvey = async () => {
      try {
        setSurveyLoading(true)

        const res = await Api.getCodes()

        if (!res.errors) {
          setSurveyLink(res.shortlink)
          setOffers(offers.concat(res.offers))
          setSurveyLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setSurveyLoading(false)
        setNotification(err, 'error')
      }
    }

    loadSurvey()
  }, [])

  useEffect(() => {
    if (user?.onboarding_info?.survey_offers?.completed || isMobileOnly) {
      return history.push('/dashboard')
    }
  }, [user])

  const handleVideoModalClose = () => {
    setVideoModalOpen(false)
    setVideoId('')
  }

  return (
    <>
      <SurveyOffersOnboardingModalParent
        user={user}
        surveyLoading={surveyLoading}
        offers={offers}
        setOffers={setOffers}
        setOffer={setOffer}
        offer={offer}
        defaultEmojiKeywords={defaultEmojiKeywords}
        setVideoId={setVideoId}
        setVideoModalOpen={setVideoModalOpen}
        surveyOffersFormModalSize={surveyOffersFormModalSize}
        setSurveyOffersFormModalSize={setSurveyOffersFormModalSize}
        surveyOffersFormModalFullWidth={surveyOffersFormModalFullWidth}
        setSurveyOffersFormModalFullWidth={setSurveyOffersFormModalFullWidth}
        surveyLink={surveyLink}
      />
      <Dialog
        fullWidth
        maxWidth="md"
        open={videoModalOpen}
        onClose={handleVideoModalClose}>
        <VideoDialogContent>
          <VideoContainer>
            <YouTube
              videoId={videoId}
              opts={videoOpts}
              style={{ width: '100%', height: '100%' }}
            />
          </VideoContainer>
        </VideoDialogContent>
      </Dialog>
    </>
  )
}

export default SurveyOffersOnboardingPageParent
