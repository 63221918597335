import { useEffect, useState, useContext } from 'react'

import { GroupContext } from '../groups/groupContext'

import {
  Select,
  MenuItem,
  CircularProgress,
  Button,
  Typography,
} from '@mui/material'

import ConfirmDialog from '../common/ConfirmDialog'

import Api from '../../services/api'

const SurveyOfferSelect = ({ event, setEvent }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [offerCodes, setOfferCodes] = useState([])
  const [confirmOpen, setConfirmOpen] = useState(false)
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)

  useEffect(() => {
    const fetchOfferCodes = async () => {
      try {
        setIsLoading(true)
        let res
        if (selectedGroup) {
          res = await Api.getAllGroupOfferCodes({
            event_id: event.id,
            group_id: selectedGroup.id,
          })
        } else {
          res = await Api.getUnlockedUserCodes({
            event_id: event.id,
          })
        }

        if (!res.errors) {
          setOfferCodes(res)
          setIsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setIsLoading(false)
      }
    }

    fetchOfferCodes()
  }, [])

  const confirmClearLockedCode = () => {
    setEvent({ ...event, eventCode: null, lockedOfferCode: null })
  }

  const clearLockedCode = () => {
    setConfirmOpen(true)
  }

  return isLoading ? (
    <CircularProgress color="primary" />
  ) : offerCodes.length > 0 ? (
    <div>
      <Select
        sx={{ minWidth: '200px' }}
        value={event.lockedOfferCode ? event.lockedOfferCode.toUpperCase() : ''}
        label="Event Code"
        displayEmpty
        onChange={(e) =>
          setEvent({
            ...event,
            // We need to differentiate in the UX the code the user is locking
            // as well as the event code that gets persisted in the DB
            lockedOfferCode: e.target.value,
          })
        }>
        <MenuItem disabled value="">
          <em>Select an offer code ...</em>
        </MenuItem>
        {offerCodes.map((offerCode, i) => {
          return (
            <MenuItem value={offerCode.code} key={i}>
              {offerCode.code.toUpperCase()}
            </MenuItem>
          )
        })}
      </Select>
      <Button
        variant="contained"
        sx={{ color: 'white', marginLeft: '10px' }}
        onClick={clearLockedCode}>
        Clear Code
      </Button>
      <ConfirmDialog
        title="Clear Locked Code?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={confirmClearLockedCode}>
        <span>Are you sure you want to remove this code from this event?</span>
        <p>
          Survey responses that use this code will no longer sync into this
          event.
        </p>
      </ConfirmDialog>
    </div>
  ) : (
    <Typography variant="body1" color="textSecondary">
      No unlocked offer codes available
    </Typography>
  )
}

export default SurveyOfferSelect
