import { styled, useTheme } from '@mui/system'
import { Typography, Tooltip } from '@mui/material'

import DisabledBadgeIconWrapper from '../common/DisabledBadgeIconWrapper'

import { ReactComponent as FireIcon } from '../../icons/fireIcon_16x16.svg'
import { ReactComponent as WindIcon } from '../../icons/windIcon_16x16.svg'
import { ReactComponent as EmailIcon } from '../../icons/mailIcon_16x16.svg'
import { ReactComponent as SnowFlakeIcon } from '../../icons/snowFlakeIcon_16x16.svg'

import * as Lead from './helpers'
import { capitalize } from '../../utils/string'

const LeadTypeIconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
})

const StyledLeadTypeIcon = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'color' && prop !== 'svgType' && prop !== 'extraLeftMargin',
})(({ color, svgType, extraLeftMargin }) => ({
  display: 'flex',
  alignItems: 'center',
  // Add extra margin equal to the size of our 24x24 SVGs
  marginLeft: extraLeftMargin && '24px',
  svg: {
    path: {
      fill: svgType === 'fill' ? color : '',
      stroke: svgType === 'stroke' ? color : '',
    },
  },
}))

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})(({ expanded }) => ({
  fontWeight: expanded ? '600' : '',
}))

const LeadTypeIcon = ({
  lead,
  expanded = false,
  typographyVariant = 'body1',
}) => {
  const theme = useTheme()

  const leadTypes = lead.leadTypes || []

  const makeTooltipContent = (tooltipContent) => {
    return tooltipContent
  }

  let iconsData = []
  let type = ''
  let temperature = ''

  const parseLeadTypes = () => {
    if (lead.placeholder) {
      iconsData.push({
        color: theme.palette.neutral.extraLight,
        icon: <FireIcon />,
        svgType: 'fill',
      })
      type = 'lead'

      return
    }

    const isBookingLead = Lead.isBookingLead(leadTypes)
    const isReferLead = Lead.isReferLead(leadTypes)
    const isOptIn = Lead.isOptIn(leadTypes)
    const isFeedbackLead = leadTypes?.join('').includes('FEEDBACK')

    if (isBookingLead) {
      type = 'lead'
    } else if (isReferLead) {
      type = 'referral'
    } else if (isOptIn) {
      type = 'email'
    } else if (isFeedbackLead) {
      type = 'feedback'
    }

    if (Lead.isHotLead(lead)) {
      temperature = 'Hot'

      if (isBookingLead) {
        iconsData.push({
          color: theme.palette.error.semiDark,
          icon: <FireIcon />,
          svgType: 'fill',
          tooltip: makeTooltipContent(
            'Hot Booking Lead - This person indicated they want to book you, filled out necessary contact information, and has a verified opportunity!'
          ),
        })
      }

      if (isReferLead) {
        iconsData.push({
          color: theme.palette.error.semiDark,
          icon: <WindIcon />,
          svgType: 'stroke',
          tooltip: makeTooltipContent(
            'Hot Referral Lead - This person indicated they want to refer you, filled out necessary contact information, and has a verified opportunity!'
          ),
        })
      }
    }

    if (isOptIn) {
      iconsData.push({
        icon: <EmailIcon />,
        tooltip: 'Opted in to receive emails',
      })
    }

    if (!isOptIn) {
      iconsData.push({
        icon: <EmailIcon />,
        tooltip: isFeedbackLead
          ? 'Opted out of newsletters, but provided contact information for feedback'
          : 'Opted out of newsletters, but provided contact information for booking',
        disabled: true,
      })
    }
  }

  parseLeadTypes()

  const parseLeadTitle = (type) => {
    switch (type) {
      case 'lead':
        return `${temperature} Booking Lead`
      case 'referral':
        return `${temperature} Referral Lead`
      case 'email':
        return 'Email Opt In'
      default:
        return capitalize(type)
    }
  }

  const title = expanded ? parseLeadTitle(type) : capitalize(type)

  return (
    <LeadTypeIconContainer>
      {iconsData.map((iconData, index) =>
        iconData.disabled ? (
          <DisabledBadgeIconWrapper>
            <Tooltip
              key={index}
              title={iconData.tooltip}
              arrow
              disableInteractive
              enterTouchDelay={0}
              leaveTouchDelay={3000}>
              <StyledLeadTypeIcon
                color={iconData.color}
                svgType={iconData.svgType}
                extraLeftMargin={iconsData?.length === 1}>
                {iconData.icon}
              </StyledLeadTypeIcon>
            </Tooltip>
          </DisabledBadgeIconWrapper>
        ) : (
          <Tooltip
            key={index}
            title={iconData.tooltip}
            arrow
            disableInteractive
            enterTouchDelay={0}
            leaveTouchDelay={3000}>
            <StyledLeadTypeIcon
              color={iconData.color}
              svgType={iconData.svgType}
              extraLeftMargin={iconsData?.length === 1}>
              {iconData.icon}
            </StyledLeadTypeIcon>
          </Tooltip>
        )
      )}
      <StyledTypography variant={typographyVariant} expanded={expanded}>
        {title}
      </StyledTypography>
    </LeadTypeIconContainer>
  )
}

export default LeadTypeIcon
