import { useState, useEffect } from 'react'
import { RadioGroup } from '@mui/material'
import { ReactComponent as LinkIcon } from '../../../icons/linkIcon_16x16.svg'

import CodeBuilderSettingsTitle from './CodeBuilderSettingsTitle'
import CodeBuilderInputText from './CodeBuilderInputText'
import CodeBuilderInputIcon from './CodeBuilderInputIcon'
import CodeBuilderFileUploader from './CodeBuilderFileUploader'
import RadioButton from '../../common/RadioButton'

import { uploadToS3, presignUrl, cancelUpload } from '../../../utils/fileUpload'
import { isURL } from 'validator'

const CodeBuilderSettingsBonus = ({
  surveyOffer,
  surveyOfferLoading,
  updateCode,
  setActiveTab,
}) => {
  const [uploading, setUploading] = useState(false)
  const [acceptedFile, setAcceptedFile] = useState(null)
  const [cancelTokenSource, setCancelTokenSource] = useState(null)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [offerLink, setOfferLink] = useState(surveyOffer?.link || '')
  const [offerLinkError, setOfferLinkError] = useState(null)
  const [bonusType, setBonusType] = useState(
    surveyOffer?.selectedBonusType || ''
  )

  useEffect(() => {
    if (surveyOffer?.link !== offerLink) {
      setOfferLink(surveyOffer?.link)
    }
  }, [surveyOffer?.link])

  const handleFileUpload = async (acceptedFiles) => {
    const file = acceptedFiles[0]
    setAcceptedFile(file)

    if (!file) {
      return
    }

    try {
      const { name, type } = file

      const presignedRes = await presignUrl({ fileName: name, fileType: type })
      const { presignedUrl, fileKey } = presignedRes

      if (!presignedUrl) {
        throw 'Sorry, something went wrong uploading your file'
      }

      await uploadToS3({
        presignedUrl,
        file,
        setUploading,
        onUploadProgress: setUploadProgress,
        setCancelTokenSource,
      })
      await updateCode({ file_key: fileKey, file_name: name })
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  const handleUploadCancel = () => {
    if (cancelTokenSource) {
      cancelUpload(cancelTokenSource)
    }
    setUploading(false)
    setUploadProgress(0)
  }

  const handleUploadDelete = () => {
    setAcceptedFile(null)
    // TODO: delete file from S3
    updateCode({ file_key: null, file_name: null })
  }

  const handleChangeBonusType = async (e) => {
    await updateCode({ selected_bonus_type: e.target.value })
    setBonusType(e.target.value)
  }

  const handleOfferLinkBlur = () => {
    updateCode({ link: offerLink })
  }

  const handleOfferLinkChange = (value) => {
    setOfferLink(value)
    setActiveTab(1)
  }

  const textInputValidation = (value) => {
    // In the survey client offer link click handler
    // we add a leading // to the URL if it doesn't include http(s)://
    if (value && !isURL(value, { require_host: false })) {
      return {
        message: 'Link must be a valid URL',
        allowContinue: true,
        allowSubmit: false,
      }
    }

    if (value?.length > 500) {
      return {
        message: 'Must be 500 characters or less',
        allowContinue: false,
        allowSubmit: false,
      }
    }
  }

  return (
    <>
      <CodeBuilderSettingsTitle title="Do you want to give something to your audience?" />
      <RadioGroup
        aria-label="bonus"
        name="bonus"
        value={bonusType}
        disabled={surveyOfferLoading}
        onChange={handleChangeBonusType}>
        <RadioButton
          value="file"
          label="I want to give away a specific file or document (PDF, DOC, PNG)"
        />
        {bonusType === 'file' && (
          <CodeBuilderFileUploader
            surveyOffer={surveyOffer}
            uploading={uploading}
            acceptedFile={acceptedFile}
            uploadProgress={uploadProgress}
            handleFileUpload={handleFileUpload}
            handleUploadCancel={handleUploadCancel}
            handleUploadDelete={handleUploadDelete}
          />
        )}
        <RadioButton
          value="link"
          label="I want to share a link (eg. a Dropbox link to multiple files)"
        />
        {bonusType === 'link' && (
          <CodeBuilderInputText
            value={offerLink || ''}
            handleOnChange={handleOfferLinkChange}
            handleOnBlur={handleOfferLinkBlur}
            placeholder="Paste a link to your bonus"
            canEdit={true}
            error={offerLinkError}
            setError={setOfferLinkError}
            disabled={surveyOfferLoading}
            hint="Use a link to give away more than one bonus"
            marginTop="1.5rem"
            containerHeight="auto"
            autoFocus={!offerLink}
            inputValidation={textInputValidation}
            inputDescription={
              <CodeBuilderInputIcon icon={<LinkIcon />} fillType="stroke" />
            }
          />
        )}
        <RadioButton value="none" label="I do not want to give away anything" />
      </RadioGroup>
    </>
  )
}

export default CodeBuilderSettingsBonus
