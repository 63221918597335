import { styled } from '@mui/system'

import SurveyOffersOnboardingHeader from './SurveyOffersOnboardingHeader'
import SurveyOffersOnboardingCta from './SurveyOffersOnboardingCta'
import SpeakerSlide from '../../common/SpeakerSlideResponsive'

const ButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '2rem',
})

const FlexWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const SurveyOffersOnboardingStepThree = ({
  user,
  advanceLastStep,
  loading,
}) => {
  const breakPoint = 850
  const isBelowBreakpoint = window.innerWidth < breakPoint

  return (
    <>
      <SurveyOffersOnboardingHeader>
        This is a Talkadot Slide. It contains your QR code and your unique link
        to collect feedback.
        <br />
        Before you create your first code, let's start with a quick explanation
        of how this works.
      </SurveyOffersOnboardingHeader>
      <FlexWrapper>
        <SpeakerSlide
          avatar={user.avatar}
          surveyLink={user.shortlink}
          socialLinks={user.socialLinks}
          email={user.email}
          name={user.first_name}
          size={'lg'}
          onboardingPlaceholder={true}
        />
      </FlexWrapper>
      <ButtonContainer>
        <SurveyOffersOnboardingCta
          buttonText="Let's go!"
          handleClick={() => advanceLastStep(2)}
          loading={loading}
          fontSize={isBelowBreakpoint ? '1.125rem' : '1.625rem'}
        />
      </ButtonContainer>
    </>
  )
}

export default SurveyOffersOnboardingStepThree
