import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import VideoPlayer from '../../common/VideoPlayer'
import {
  Card,
  CardVideoContainer,
  CardInnerVideo,
} from './DashboardCardComponents'

const StyledHeader = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
}))

const StyledHeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  textAlign: 'center',
}))

const CommunityLink = styled('a')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: '600',
  cursor: 'pointer',
  textDecoration: 'none',
}))

const VideoParent = () => {
  return (
    <Card>
      <CardVideoContainer>
        <StyledHeaderWrapper>
          <StyledHeader variant="body2">
            Get the most out of your Talkadot Experience. Watch this video
            series
          </StyledHeader>
          <Typography variant="body1">
            Want more ideas?
            <CommunityLink
              href="https://talk.talkadot.com/sign_in"
              target="_blank">
              {' '}
              Head to our Talkadot Community{' '}
            </CommunityLink>
            to hear from fellow unicorn speakers.
          </Typography>
        </StyledHeaderWrapper>
        <CardInnerVideo>
          <VideoPlayer videoId="XXlVQRswLFM" />
        </CardInnerVideo>
      </CardVideoContainer>
    </Card>
  )
}

export default VideoParent
