import { styled } from '@mui/system'

import { ReactComponent as QuoteOpenSingle } from '../../icons/quoteOpenSingle.svg'

const DoubleQuotesContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'close',
})(({ close }) => ({
  display: 'flex',
  gap: '3px',
  justifyContent: 'center',
  alignItems: close ? 'flex-end' : 'flex-start',
  svg: {
    transform: close ? 'rotate(180deg)' : 'rotate(0deg)',
  },
}))

const QuotationMarks = ({ close = false }) => {
  return (
    <DoubleQuotesContainer close={close}>
      <QuoteOpenSingle />
      <QuoteOpenSingle />
    </DoubleQuotesContainer>
  )
}

export default QuotationMarks
