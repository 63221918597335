import { useContext, useState } from 'react'
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { styled } from '@mui/system'
import { TextField } from '@mui/material'

import { AuthenticationContext } from './authenticationContext'
import envConfig from '../../envConfig'
import isEmail from 'validator/lib/isEmail'
import Api from '../../services/api'
import { trackConversion } from '../../services/metrics'
import { trackSignUp, trackPurchase } from '../../services/metrics'
import { getRewardfulReferralId } from '../../utils/rewardful'

import {
  RegistrationContainer,
  InnerContainer,
  CheckoutForm,
  AuthButton,
  FormWrapper,
} from './RegistrationParent'
import RegistrationHeader from './Header'
import RegistrationErrorFormatter from './RegistrationErrorFormatter'
import PricingGrid from '../payments/PricingGrid'
import TermsOfService from './TermsOfService'
import { GoToLoginPageButton } from './AuthLinkButtons'
import { YellowPaintGraphic } from '../common/SidePaintGraphics'

const stripePromise = loadStripe(envConfig.REACT_APP_STRIPE_P_KEY)

const PricingGridContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down(1028)]: {
    display: 'none',
  },
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}))

const MobilePricingGridContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up(1028)]: {
    display: 'none',
  },
  display: 'flex',
  alignItems: 'center',
  marginTop: '35px',
}))

const CreditCardContainer = styled('div')({
  margin: '20px 0',
  padding: '20px',
  border: '1px solid lightgray',
  borderRadius: '5px',
})

const StyledTextField = styled(TextField)({
  // Set input font size to 16px to prevent mobile zoom
  '& .MuiInputBase-input': {
    fontSize: '1rem',
  },
})

const SummaryAndPaymentForm = ({
  plan,
  referrer,
  referrerId,
  utmParams,
  gaParams,
  groupName,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [cardName, setCardName] = useState('')
  const [cardNameError, setCardNameError] = useState()
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [genericInlineError, setGenericInlineError] = useState(null)

  const {
    showFullPageLoader,
    hideFullPageLoader,
    handleAuthenticationSuccess,
  } = useContext(AuthenticationContext)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!email || !isEmail(email)) {
      return setEmailError('Please fill out a valid email')
    }

    if (!password) {
      return setPasswordError('please fill out a valid password')
    }

    if (password.length < 8) {
      return setPasswordError('Password must be at least 8 characters')
    }

    // Dont allow double clicks
    if (checkoutLoading) {
      return false
    }

    if (!stripe || !elements) {
      setCheckoutLoading(false)
      return
    }

    if (!cardName) {
      setCheckoutLoading(false)
      return setCardNameError('Please fill out name on card')
    }

    setCheckoutLoading(true)
    showFullPageLoader('Creating Your Account!')

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: cardName,
      },
    })

    if (payload.error) {
      setCheckoutLoading(false)
      hideFullPageLoader()

      return setGenericInlineError(
        <RegistrationErrorFormatter error={payload.error} errorType="stripe" />
      )
    }

    try {
      const { utm_source, utm_medium, utm_campaign } = utmParams
      const { ga_id, gcl_id } = gaParams
      const params = {
        plan_token: plan.token,
        registration: {
          email: email,
          password: password,
          referrer: referrer,
          referrer_user_id: referrerId,
          utm_source,
          utm_medium,
          utm_campaign,
          ga_id,
          gcl_id,
          rewardful_referrer_id: getRewardfulReferralId(),
        },
        stripe_card_info: {
          ...payload.paymentMethod,
        },
      }

      const res = await Api.registerPaidUser(params)

      if (!res.errors) {
        trackConversion({ plan: plan?.name, value: plan?.price })
        trackPurchase({
          plan: plan,
          value: plan?.price,
          // Note: the transaction_id is the charge_id from the payment_intent in latest_invoice returned from stripe Subscription.create
          transactionId: res?.transaction_id,
          // Note: Coupon is not passed because it only applies to upgrades while on trial
        })
        trackSignUp(res.auth?.user?.id, res.auth?.user?.email)
        setCheckoutLoading(false)
        hideFullPageLoader()
        return handleAuthenticationSuccess(res.auth)
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      setCheckoutLoading(false)
      setGenericInlineError(
        <RegistrationErrorFormatter
          email={email}
          error={err}
          errorType="talkadot-api"
        />
      )
    }
  }

  return (
    <RegistrationContainer>
      <YellowPaintGraphic>
        {plan && (
          <PricingGridContainer>
            <PricingGrid plan={plan} selectedPlan={plan} isCurrentPlan={true} />
          </PricingGridContainer>
        )}
      </YellowPaintGraphic>
      <InnerContainer>
        <GoToLoginPageButton />
        <FormWrapper>
          <RegistrationHeader
            referrer={referrer}
            plan={plan}
            groupName={groupName}
            genericWelcomeMessage="Get actionable feedback and generate speaking leads!"
          />
          {genericInlineError}
          <CheckoutForm>
            <StyledTextField
              required
              fullWidth
              margin="normal"
              variant="outlined"
              label="Email"
              type="email"
              value={email}
              error={!!emailError}
              helperText={emailError && emailError}
              onChange={(e) => {
                setGenericInlineError(null)
                setEmailError(null)
                setEmail(e.target.value)
              }}
            />
            <StyledTextField
              required
              fullWidth
              margin="normal"
              error={!!passwordError}
              variant="outlined"
              label="Password"
              type="password"
              autoComplete="current-password"
              value={password}
              helperText={passwordError && passwordError}
              onChange={(e) => {
                setGenericInlineError(null)
                setPasswordError(null)
                setPassword(e.target.value)
              }}
            />
            <StyledTextField
              fullWidth
              required
              margin="normal"
              helperText={cardNameError && cardNameError}
              value={cardName}
              error={cardNameError}
              label="Name On Card"
              placeholder="Name On Card"
              onChange={(e) => {
                setGenericInlineError(null)
                setCardNameError(null)
                setCardName(e.target.value)
              }}
            />
            <CreditCardContainer>
              <CardElement
                options={{
                  style: { empty: { color: 'lightgray', fontSize: '14px' } },
                }}
              />
            </CreditCardContainer>
            <TermsOfService />
            <AuthButton
              color="primary"
              variant="contained"
              onClick={(e) => handleSubmit(e)}
              disabled={checkoutLoading}>
              Create My Account
            </AuthButton>
          </CheckoutForm>
          {plan && (
            <MobilePricingGridContainer>
              <PricingGrid
                plan={plan}
                selectedPlan={plan}
                isCurrentPlan={true}
              />
            </MobilePricingGridContainer>
          )}
        </FormWrapper>
      </InnerContainer>
    </RegistrationContainer>
  )
}

const RegistrationCheckout = ({
  plan,
  referrer,
  referrerId,
  utmParams,
  gaParams,
}) => {
  return (
    <Elements stripe={stripePromise}>
      <SummaryAndPaymentForm
        plan={plan}
        referrer={referrer}
        referrerId={referrerId}
        utmParams={utmParams}
        gaParams={gaParams}
      />
    </Elements>
  )
}

export default RegistrationCheckout
