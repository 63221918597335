import { useContext, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import { Drawer, List } from '@mui/material'
import { styled } from '@mui/system'

import { AuthenticationContext } from '../authentication/authenticationContext'
import NavItems from './NavItems'
import UpgradeWidget from '../common/UpgradeWidget'
import UpgradeButton from '../common/UpgradeButton'

import {
  drawerWidth,
  drawerWidthCollapsed,
  drawerWidthMobile,
  upperNavHeight,
  upperNavHeightMobile,
} from '../../styles/layoutNavConstants'

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  overflowY: 'scroll',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const ListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 5,
  marginTop: theme.spacing(1),
}))

const UpgradeWidgetContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: theme.spacing(3, 1.5),
}))

const DrawerHeaderLayout = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  paddingTop: theme.spacing(3),
  minHeight: upperNavHeight,
  backgroundColor: theme.palette.base.white,
  borderBottom: `1px solid ${theme.palette.border.light}`,
  zIndex: 1301,
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(1),
    minHeight: upperNavHeightMobile,
  },
}))

const LogoContainer = styled(Link)({
  display: 'flex',
  height: '95px',
})

const MainLogo = styled('img', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: open ? '214px' : '64px',
  height: 'auto',
  maxHeight: '93.4px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [theme.breakpoints.down('sm')]: {
    width: open ? '214px' : '48px',
  },
}))

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  borderRight: 'none',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: drawerWidthMobile,
  borderRight: 'none',
  [theme.breakpoints.up('sm')]: {
    width: drawerWidthCollapsed,
  },
})

const LeftNav = ({
  open,
  handleAutoOpenDrawer,
  handleAutoCloseDrawer,
  closeDrawer,
}) => {
  const {
    authData: { user },
  } = useContext(AuthenticationContext)

  const [isScrolling, setIsScrolling] = useState(false)
  const touchStartY = useRef(0)

  return (
    <StyledDrawer
      variant="permanent"
      open={open}
      onPointerLeave={handleAutoCloseDrawer}>
      <DrawerHeaderLayout>
        <LogoContainer to={{ pathname: '/dashboard' }}>
          <MainLogo
            src={`https://d3qvkz8495pjoq.cloudfront.net/talkadotLogo${
              open ? 'Lg' : 'Sm'
            }.svg`}
            alt="Talkadot!"
            open={open}
          />
        </LogoContainer>
      </DrawerHeaderLayout>
      <ListContainer>
        <List>
          <NavItems
            open={open}
            closeDrawer={closeDrawer}
            isScrolling={isScrolling}
            setIsScrolling={setIsScrolling}
            touchStartY={touchStartY}
            handleAutoOpenDrawer={handleAutoOpenDrawer}
          />
        </List>
      </ListContainer>
      <UpgradeWidgetContainer>
        {open ? (
          <UpgradeWidget />
        ) : (
          user.membership.shouldShowUpgrades && (
            <UpgradeButton showText={false} />
          )
        )}
      </UpgradeWidgetContainer>
    </StyledDrawer>
  )
}

export default LeftNav
