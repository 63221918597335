import { useTheme } from '@mui/material/styles'
import { Typography, Skeleton } from '@mui/material'
import { styled } from '@mui/system'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  flex: 1,
  marginTop: theme.spacing(1.5),
}))

const RowContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '80px 1fr 40px',
  gap: theme.spacing(1.5),
}))

const OuterProgressBar = styled('div')(({ theme }) => ({
  minWidth: '100px',
  width: '100%',
  height: '20px',
  backgroundColor: theme.palette.neutral.extraLight,
  borderRadius: theme.shape.borderRadius.xxxs,
}))

const InnerProgressBar = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'backgroundColor',
})(({ theme, width, backgroundColor }) => ({
  height: '100%',
  width: width,
  backgroundColor: backgroundColor,
  borderRadius: theme.shape.borderRadius.xxxs,
}))

const TalkEngagementStat = ({ isLoading, engagementBreakdown }) => {
  const theme = useTheme()

  const ACTIONABLE = 'actionable'
  const ENGAGING = 'engaging'
  const INSPIRING = 'inspiring'
  const RELEVANT = 'relevant'
  const INTERACTIVE = 'interactive'

  const isValidLabel = (label) => {
    return !label?.includes('{{') && label?.trim().split(/\s+/).length <= 2
  }

  const formatLabelFromLegacyKeywords = (content) => {
    if (content.includes(ACTIONABLE)) {
      return ACTIONABLE
    }

    if (content.includes(ENGAGING)) {
      return ENGAGING
    }

    if (content.includes(INSPIRING)) {
      return INSPIRING
    }

    if (content.includes(RELEVANT)) {
      return RELEVANT
    }

    if (content.includes(INTERACTIVE)) {
      return INTERACTIVE
    }

    return
  }

  const formatLabel = (label) => {
    let formattedLabel = formatLabelFromLegacyKeywords(label)

    if (!formattedLabel) {
      return label
    }

    return formattedLabel
  }

  const sortedEngagementBreakdown = engagementBreakdown
    .map((engagementStat, i) => {
      if (engagementStat.label) {
        return {
          ...engagementStat,
          label: formatLabel(engagementStat.label),
        }
      } else {
        return null
      }
    })
    .filter((engagementStat) =>
      isValidLabel(engagementStat?.label) ? { ...engagementStat } : null
    )
    .sort((a, b) => parseFloat(b?.avg_value) - parseFloat(a?.avg_value))

  const makeBarValue = (engagementStatValue) => {
    if (!engagementStatValue) {
      return
    } else {
      // All the numbers are on a scale of 1-5, so need to adjust this for a scale of 100%
      return parseFloat(engagementStatValue * 20).toFixed()
    }
  }

  const COLOR_CODES = [
    theme.palette.error.main,
    theme.palette.success.semiDark,
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.error.main,
    theme.palette.success.semiDark,
    theme.palette.primary.main,
    theme.palette.secondary.main,
  ]

  const renderEngagementRow = (engagementType, index) => {
    const barValue = makeBarValue(engagementType.avg_value)

    return (
      engagementType.label && (
        <RowContainer key={index}>
          <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
            {engagementType.label}
          </Typography>
          <OuterProgressBar>
            {barValue ? (
              <>
                <InnerProgressBar
                  width={barValue + '%'}
                  backgroundColor={COLOR_CODES[index]}
                />
              </>
            ) : (
              <Typography variant="body1">N/A</Typography>
            )}
          </OuterProgressBar>
          <Typography variant="body1">{barValue}%</Typography>
        </RowContainer>
      )
    )
  }

  const renderLoadingState = () => (
    <Skeleton width={400} height={200} variant="rectangular" />
  )

  const renderEngagementStats = () => (
    <Container>
      {sortedEngagementBreakdown?.slice(0, 4)?.map((engagementType, i) => {
        return renderEngagementRow(engagementType, i)
      })}
    </Container>
  )

  return isLoading ? renderLoadingState() : renderEngagementStats()
}

export default TalkEngagementStat
