import { styled } from '@mui/system'
import { Typography, Skeleton } from '@mui/material'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { useTheme } from '@mui/material/styles'

import {
  DonutStatsContainer,
  PrimaryDonutContainer,
  SecondaryDonutsContainer,
  SecondaryDonutStatContainer,
  SecondaryDonutContainer,
  SecondaryDonutsColumn,
} from '../common/StatCard'

const SecondaryDonutLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.veryDark,
  maxWidth: '52px',
  textWrap: 'wrap',
}))

ChartJS.register(ArcElement, Tooltip)

const TalkReattendStat = ({ reattendBreakdown, isLoading }) => {
  const theme = useTheme()
  const { d_yes, p_yes, p_no, d_no, total_reattend_answers } = reattendBreakdown

  const originalData = [d_no || 0, p_no || 0, p_yes || 0, d_yes || 0]
  const isAllZero = originalData.every((item) => item === 0)

  const chartData = {
    labels: ['Definitely Not', 'Probably Not', 'Probably', 'Definitely'],
    datasets: [
      {
        data: isAllZero ? [1] : originalData,
        backgroundColor: isAllZero
          ? [theme.palette.neutral.lightest]
          : [
              theme.palette.neutral.extraLight,
              theme.palette.neutral.dark,
              theme.palette.secondary.light,
              theme.palette.secondary.main,
            ],
        borderWidth: 0,
        cutout: '60%',
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <DonutStatsContainer>
      {isLoading ? (
        <Skeleton width={200} height={30} animation="wave" />
      ) : (
        <>
          <PrimaryDonutContainer>
            <Doughnut data={chartData} options={options} />
          </PrimaryDonutContainer>
          <SecondaryDonutsContainer>
            <SecondaryDonutsColumn>
              <SecondaryDonutStatContainer>
                <SecondaryDonutLabel variant="caption">
                  Definitely Yes
                </SecondaryDonutLabel>
                <SecondaryDonutContainer>
                  <CircularProgressbar
                    value={d_yes}
                    maxValue={total_reattend_answers}
                    text={d_yes || '0'}
                    strokeWidth={10}
                    styles={buildStyles({
                      textSize: '25px',
                      textColor: 'black',
                      pathColor: theme.palette.secondary.main,
                    })}
                  />
                </SecondaryDonutContainer>
              </SecondaryDonutStatContainer>
              <SecondaryDonutStatContainer>
                <SecondaryDonutLabel variant="caption">
                  Probably Yes
                </SecondaryDonutLabel>
                <SecondaryDonutContainer>
                  <CircularProgressbar
                    value={p_yes}
                    maxValue={total_reattend_answers}
                    text={p_yes || '0'}
                    strokeWidth={10}
                    styles={buildStyles({
                      textColor: 'black',
                      pathColor: theme.palette.secondary.light,
                      textSize: '25px',
                    })}
                  />
                </SecondaryDonutContainer>
              </SecondaryDonutStatContainer>
            </SecondaryDonutsColumn>
            <SecondaryDonutsColumn>
              <SecondaryDonutStatContainer>
                <SecondaryDonutLabel variant="caption">
                  Definitely Not
                </SecondaryDonutLabel>
                <SecondaryDonutContainer>
                  <CircularProgressbar
                    value={d_no}
                    maxValue={total_reattend_answers}
                    text={d_no || '0'}
                    strokeWidth={10}
                    counterClockwise={true}
                    styles={buildStyles({
                      textSize: '25px',
                      textColor: 'black',
                      pathColor: theme.palette.neutral.extraLight,
                    })}
                  />
                </SecondaryDonutContainer>
              </SecondaryDonutStatContainer>
              <SecondaryDonutStatContainer>
                <SecondaryDonutLabel variant="caption">
                  Probably Not
                </SecondaryDonutLabel>
                <SecondaryDonutContainer>
                  <CircularProgressbar
                    value={p_no}
                    maxValue={total_reattend_answers}
                    text={p_no || '0'}
                    counterClockwise={true}
                    strokeWidth={10}
                    styles={buildStyles({
                      textColor: 'black',
                      pathColor: theme.palette.neutral.dark,
                      textSize: '25px',
                    })}
                  />
                </SecondaryDonutContainer>
              </SecondaryDonutStatContainer>
            </SecondaryDonutsColumn>
          </SecondaryDonutsContainer>
        </>
      )}
    </DonutStatsContainer>
  )
}

export default TalkReattendStat
