import { styled } from '@mui/system'
import { Tooltip } from '@mui/material'

import TableChip from '../common/TableChip'

import { formatDateWithTimeZone } from '../common/helpers'
import { humanizedFollowupFailure } from './helpers'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: theme.spacing(1),
  gap: theme.spacing(0.5),
}))

const LeadsTags = ({ lead }) => {
  const {
    viewed,
    contacted,
    followupSentAt,
    followupFailure,
    speakerflowSyncedAt,
    zapierSyncedAt,
    upcomingSpeakerflowSyncTime,
    upcomingFollowupSendTime,
    // upcomingFollowupBonusWarning,
  } = lead

  const shouldShowNewTag = !viewed
  const shouldShowEmailTag = followupSentAt || followupFailure
  const shouldShowCrmTag = zapierSyncedAt || speakerflowSyncedAt
  const shouldShowScheduledTag =
    upcomingSpeakerflowSyncTime || upcomingFollowupSendTime
  const shouldShowContactedTag =
    !shouldShowEmailTag &&
    !shouldShowCrmTag &&
    !shouldShowScheduledTag &&
    contacted

  const renderNewTag = () => (
    <TableChip color="green" text="New!" component="div" />
  )

  const renderEmailTag = () => {
    let tagColor
    let toolTipText

    if (followupFailure) {
      toolTipText = humanizedFollowupFailure(lead)
      tagColor = 'red'
    } else {
      tagColor = 'grey'
      toolTipText = `Post event email sent out on ${formatDateWithTimeZone(
        followupSentAt
      )}`
    }

    return (
      <Tooltip
        title={toolTipText}
        placement="top-start"
        disableInteractive
        enterTouchDelay={0}
        leaveTouchDelay={3000}>
        <span>
          <TableChip color={tagColor} text={'Emailed'} component="div" />
        </span>
      </Tooltip>
    )
  }

  const renderCrmTag = () => {
    let toolTipText

    if (zapierSyncedAt && speakerflowSyncedAt) {
      toolTipText = `Pushed to Speakerflow as a lead on ${formatDateWithTimeZone(
        speakerflowSyncedAt
      )}.  Pushed to Zapier on ${formatDateWithTimeZone(zapierSyncedAt)}`
    } else if (speakerflowSyncedAt) {
      toolTipText = `Pushed to Speakerflow as a lead on ${formatDateWithTimeZone(
        speakerflowSyncedAt
      )}`
    } else {
      toolTipText = `Pushed to Zapier as a lead on ${formatDateWithTimeZone(
        zapierSyncedAt
      )}`
    }

    return (
      <Tooltip
        title={toolTipText}
        placement="top-start"
        disableInteractive
        enterTouchDelay={0}
        leaveTouchDelay={3000}>
        <span>
          <TableChip color="grey" text="CRM" component="div" />
        </span>
      </Tooltip>
    )
  }

  const renderScheduledTag = () => {
    let toolTipText

    if (upcomingFollowupSendTime && upcomingSpeakerflowSyncTime) {
      toolTipText = `Post event email scheduled for ${upcomingFollowupSendTime}.  Speakerflow push scheduled for ${upcomingSpeakerflowSyncTime}`
    } else if (upcomingFollowupSendTime) {
      toolTipText = `Post event email scheduled for ${upcomingFollowupSendTime}`
    } else {
      toolTipText = `This contact is scheduled to be pushed to Speakerflow on ${upcomingSpeakerflowSyncTime}`
    }

    return (
      <Tooltip
        title={toolTipText}
        placement="top-start"
        disableInteractive
        enterTouchDelay={0}
        leaveTouchDelay={3000}>
        <span>
          <TableChip color="grey" text="Scheduled" component="div" />
        </span>
      </Tooltip>
    )
  }

  const renderContactedTag = () => (
    <TableChip color="grey" text="Contacted" component="div" />
  )

  return (
    <Container>
      {shouldShowNewTag && renderNewTag()}
      {shouldShowEmailTag && renderEmailTag()}
      {shouldShowCrmTag && renderCrmTag()}
      {shouldShowScheduledTag && renderScheduledTag()}
      {shouldShowContactedTag && renderContactedTag()}
    </Container>
  )
}

export default LeadsTags
