import React, { useContext } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import * as Cookies from '../services/cookies'

import LoginForm from '../features/authentication/LoginForm'
import RegistrationParent from '../features/authentication/RegistrationParent'
import ReferrerRegistrationParent from '../features/authentication/ReferrerRegistrationParent'
import DashboardParent from '../features/dashboard/v2/DashboardParent'
import OnboardingParent from '../features/onboarding/OnboardingParent'
import SurveyOffersOnboardingParent from '../features/onboarding/surveyOffers/SurveyOffersOnboardingParent'
import AccountSettingsParent from '../features/accountSettings/AccountSettingsParent'
import TeamParent from '../features/accountSettings/TeamParent'
import LeadsParent from '../features/leads/LeadsParent'
import LeadsFollowupParent from '../features/leads/LeadsFollowupParent'
import LeadsIntegrationsParent from '../features/leads/LeadsIntegrationsParent'
import ReferParent from '../features/refer/ReferParent'
import EventReportCardParent from '../features/eventReportCards/EventReportCardParent'
import SocialShareImageParent from '../features/eventReportCards/SocialShareImageParent'
import PasswordResetRequest from '../features/authentication/PasswordResetRequest'
import PasswordResetForm from '../features/authentication/PasswordResetForm'
import { LinkedInCallback } from 'react-linkedin-login-oauth2'
import { AuthenticationContext } from '../features/authentication/authenticationContext'
import { usePermissionHelper } from '../utils/permission_helper'
import AdminParent from '../features/admin/AdminParent'
import AdminChapterUploadParent from '../features/admin/AdminChapterUploadParent'
import AdminAssociatesParent from '../features/admin/AdminAssociatesParent'
import EventPlannerDashboard from '../features/eventPlanners/EventPlannerDashboard'
import EventGroupParent from '../features/eventPlanners/EventGroupParent'
import PublicProfileParent from '../features/publicProfile/PublicProfileParent'
import BillingParent from '../features/accountSettings/BillingParent'
import ChapterGroupsParent from '../features/groups/ChapterGroupsParent'
import GroupParent from '../features/groups/GroupParent'
import GroupInivtationAcceptanceParent from '../features/groups/GroupInvitationAcceptanceParent'
import OAuthProviderAuthenticationParent from '../features/authentication/OAuthProviderAuthenticationParent'
import UnsubscribeParent from '../features/unsubscribe/UnsubscribeParent'
import BonusGiftParent from '../features/bonusGift/BonusGiftParent'
import ApiDocumentationParent from '../features/api/ApiDocumentationParent'
import TalksParent from '../features/talks/TalksParent'
import CodesParent from '../features/codes/CodesParent'
import CodeBuilderParent from '../features/codes/builder/CodeBuilderParent'
import CommissionPayoutParent from '../features/admin/CommissionPayoutParent'
import ReferralUploadParent from '../features/admin/ReferralUploadParent'
import ShowUserParent from '../features/admin/ShowUserParent'

// If user is logged in, redirect them to the homepage
const UnAuthRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = Cookies.getAuthToken()

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return <Component {...props} />
        }

        return <Redirect to="/welcome" />
      }}
    />
  )
}

// Routes that you need to be logged in to use
const ProtectedRoute = ({ component, ...rest }) => {
  const isAuthenticated = Cookies.getAuthToken()

  const routeComponent = (props) =>
    isAuthenticated ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    )

  return <Route {...rest} render={routeComponent} />
}

const AdminRoute = ({ component, ...rest }) => {
  const { authData } = useContext(AuthenticationContext)
  const { isAdmin } = authData?.user

  // Make sure authData is loaded before checking if user is admin to allow refreshing of admin page without redirecting
  if (!authData.user.id) {
    return null
  }

  if (!isAdmin) {
    return <Redirect to={{ pathname: '/dashboard' }} />
  }

  return <ProtectedRoute component={component} {...rest} />
}

const InvitationRoute = ({ component, ...rest }) => {
  const isAuthenticated = Cookies.getAuthToken()
  const location = useLocation()

  if (isAuthenticated) {
    return <ProtectedRoute component={component} {...rest} />
  }

  return <Redirect to={{ pathname: `/register`, search: location.search }} />
}

const DashboardRoute = ({ component, ...rest }) => {
  const { authData } = useContext(AuthenticationContext)
  const isEventPlanner = authData?.user?.isEventPlanner

  if (isEventPlanner) {
    return <Redirect to="/event-planner/dashboard" />
  } else {
    return <ProtectedRoute component={DashboardParent} {...rest} />
  }
}

const Routes = () => {
  const { authData } = useContext(AuthenticationContext)
  const { hasGroupsFeatureFlag } = authData?.user
  const { canAccessTeams } = usePermissionHelper()
  return (
    <Switch>
      <Route
        path="/event-report/:sharelink"
        component={EventReportCardParent}
      />
      <Route
        path="/social-share-images/:sharelink/:answerId?"
        component={SocialShareImageParent}
      />
      <Route
        exact
        path="/s/:shortlink/event-report/:sharelink"
        component={EventReportCardParent}
      />
      <Route path="/request-password-reset" component={PasswordResetRequest} />
      <Route
        path="/password-reset/:passwordResetToken"
        component={PasswordResetForm}
      />
      <Route path="/s/:shortlink" component={PublicProfileParent} />
      <Route path="/unsubscribe" component={UnsubscribeParent} />
      <Route path="/thank-you-gift" component={BonusGiftParent} />
      <AdminRoute
        path="/admin/chapter_uploads"
        component={AdminChapterUploadParent}
      />
      <AdminRoute
        path="/admin/commission-payouts"
        component={CommissionPayoutParent}
      />
      <AdminRoute
        path="/admin/referral-uploads"
        component={ReferralUploadParent}
      />
      <AdminRoute path="/admin/user/:userId" component={ShowUserParent} />
      <AdminRoute path="/admin/associates" component={AdminAssociatesParent} />
      <AdminRoute path="/admin" component={AdminParent} />
      <UnAuthRoute path="/register" component={RegistrationParent} />
      <UnAuthRoute path="/login" component={LoginForm} />
      <UnAuthRoute path="/referrer" component={ReferrerRegistrationParent} />
      <Route path="/api/docs" component={ApiDocumentationParent} />
      <ProtectedRoute path="/welcome" component={OnboardingParent} />
      <ProtectedRoute path="/account/billing" exact component={BillingParent} />
      <ProtectedRoute
        path="/account/settings"
        exact
        component={AccountSettingsParent}
      />
      <ProtectedRoute
        path="/survey-onboarding"
        component={SurveyOffersOnboardingParent}
      />
      {canAccessTeams() && (
        <ProtectedRoute path="/account/team" exact component={TeamParent} />
      )}
      <DashboardRoute path="/dashboard" component={DashboardParent} />
      <ProtectedRoute path="/talks" component={TalksParent} />
      <ProtectedRoute path="/codes" exact component={CodesParent} />
      <ProtectedRoute
        path="/codes/edit/:surveyOfferId?"
        component={CodeBuilderParent}
      />
      <ProtectedRoute path="/leads" exact component={LeadsParent} />
      <ProtectedRoute path="/leads/follow-up" component={LeadsFollowupParent} />
      <ProtectedRoute
        path="/leads/integrations"
        component={LeadsIntegrationsParent}
      />
      <ProtectedRoute path="/refer-and-earn" component={ReferParent} />
      {hasGroupsFeatureFlag && (
        <ProtectedRoute exact path="/groups" component={ChapterGroupsParent} />
      )}
      <ProtectedRoute exact path="/groups/:id/events" component={GroupParent} />
      <InvitationRoute
        path="/accept-group-invitation"
        component={GroupInivtationAcceptanceParent}
      />
      <ProtectedRoute
        path="/event-planner/dashboard"
        component={EventPlannerDashboard}
      />
      <ProtectedRoute
        path="/event-planner/event-groups/:eventGroupId"
        component={EventGroupParent}
      />
      <Route
        path="/oauth/authorize"
        component={OAuthProviderAuthenticationParent}
      />
      <Route exact path="/linkedin" component={LinkedInCallback} />
      <Redirect from="/upgrade" to="/account/billing" exact />
      <Redirect from="/account" to="/account/settings" exact />
      <Redirect to={'/dashboard'} />
    </Switch>
  )
}

export default Routes
