import { useEffect, useState, useContext } from 'react'
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Skeleton,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { styled } from '@mui/system'

import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { GroupContext } from '../groups/groupContext'
import { usePermissionHelper } from '../../utils/permission_helper'
import { getComparator } from '../../utils/sort'

import CodesTableHeader from './CodesTableHeader'
import CodesTableRow from './CodesTableRow'
import TablePaginationFooter from '../common/TablePaginationFooter'
import ConfirmDialog from '../common/ConfirmDialog'
import CreateCodeAlert from '../common/CreateCodeAlert'
import { VideoPlayerComponent } from '../common/VideoPlayerComponent'

const VideoTableRow = styled(TableRow)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const CodesTable = ({
  offers,
  setOffers,
  loading,
  handleEdit,
  handleDuplicate,
  canDuplicate,
  handleOpenSlideDownload,
  handleDownloadQrCode,
}) => {
  const [page, setPage] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(5)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('createdAt')
  const [visibleRows, setVisibleRows] = useState([])
  const [deleteOfferId, setDeleteOfferId] = useState(null)
  const [confirmOpen, setConfirmOpen] = useState(false)

  const { setNotification, updatePermission } = useContext(
    AuthenticationContext
  )
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)

  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { findPermission } = usePermissionHelper()
  const codePermission = findPermission('OFFER_CODE_LIMIT')

  const rowsPerPage = 25

  useEffect(() => {
    setVisibleRows(
      offers
        .slice()
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    )
  }, [offers, page, rowsPerPage, order, orderBy])

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleConfirmDelete = async () => {
    try {
      let res
      if (selectedGroup) {
        res = await Api.deleteGroupSurveyOffer(deleteOfferId, selectedGroup.id)
      } else {
        res = await Api.deleteCode(deleteOfferId)
      }
      if (!res.errors) {
        setOffers(offers?.filter((offer) => offer.id !== deleteOfferId))
        setDeleteOfferId(null)

        // Update the upgrade widget content
        updatePermission({
          ...codePermission,
          consumed: codePermission.consumed - 1,
        })
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
      setDeleteOfferId(null)
    }
  }

  const handleDelete = async (offerId) => {
    setDeleteOfferId(offerId)
    setConfirmOpen(true)
  }

  const deleteOfferCode = () => {
    if (!deleteOfferId) return null
    const offer = offers.find((offer) => offer.id === deleteOfferId)
    return offer?.code
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - offers.length) : 0

  const skeletonColumns = smallScreen ? 1 : 3

  const renderLoadingSkeleton = () =>
    Array.from({ length: 3 }).map((_, index) => (
      <TableRow key={index}>
        {Array.from({ length: skeletonColumns }).map((_, cellIndex) => (
          <TableCell key={cellIndex}>
            <Skeleton />
          </TableCell>
        ))}
        <TableCell align="right" />
      </TableRow>
    ))

  const renderTableRows = () => {
    if (loading) {
      return renderLoadingSkeleton()
    }

    return visibleRows.map((offer) => (
      <CodesTableRow
        key={`codes-table-${offer.id}`}
        offer={offer}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleDuplicate={handleDuplicate}
        canDuplicate={canDuplicate}
        handleOpenSlideDownload={handleOpenSlideDownload}
        handleDownloadQrCode={handleDownloadQrCode}
      />
    ))
  }

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="codes table" stickyHeader>
          <CodesTableHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {renderTableRows()}
            {!loading && offers?.length <= 1 && (
              <VideoTableRow>
                <TableCell colSpan={10} align="center">
                  <CreateCodeAlert />
                  {/* TODO: Replace with Arel's video when it's done */}
                  <VideoPlayerComponent
                    title="Watch this video on how to make the most out of your code."
                    videoId="XihXcHOBDjU"
                  />
                </TableCell>
              </VideoTableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 57 * emptyRows,
                }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePaginationFooter
        rows={offers}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
      />
      <ConfirmDialog
        title="Are you sure you want to delete this survey code"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirmDelete}
        onCancel={() => setDeleteOfferId(null)}
        buttonColor="black"
        buttonText="Delete Code"
        manualConfirm={true}
        confirmText="DELETE CODE">
        <>
          You will no longer be able to use survey code{' '}
          <strong>{deleteOfferCode()}</strong> to collect feedback.
        </>
      </ConfirmDialog>
    </>
  )
}

export default CodesTable
