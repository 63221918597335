import { styled } from '@mui/system'
import { Typography } from '@mui/material'

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  backgroundColor: theme.palette.base.white,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
}))

const ChipContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'visible',
})(({ theme, visible }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(0.75),
  borderRadius: theme.shape.borderRadius.xs,
  boxShadow: theme.shape.boxShadow.xs,
  padding: theme.spacing(1.5, 0.5, 0.5, 0.5),
  width: '72px',
  visibility: visible ? 'visible' : 'hidden',
}))

const ChipIcon = styled(Typography)(() => ({
  svg: {
    width: '24px',
    height: '22.33px',
  },
}))

const ChipValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const ChipLabel = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.neutral.veryDark,
}))

const ChipList = ({ items }) => {
  return (
    <Container>
      {items?.map(({ svgType, icon, value, label, visible = true }, index) => (
        <ChipContainer key={index} visible={visible}>
          <ChipIcon>{icon}</ChipIcon>
          <ChipValue variant="h4">{value}</ChipValue>
          <ChipLabel variant="caption">{label}</ChipLabel>
        </ChipContainer>
      ))}
    </Container>
  )
}

export default ChipList
