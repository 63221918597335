import { useContext } from 'react'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import { Notification } from '../../common/Notification'
import { styled } from '@mui/system'

import {
  usePermissionHelper,
  ELITE_TIER,
  PRO_TIER,
  LITE_TIER,
} from '../../../utils/permission_helper'

const UpgradeLink = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: 'pointer',
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

const CodeBuilderUpgradeNotification = ({ currentQuestion }) => {
  const { toggleUpgradeModal } = useContext(AuthenticationContext)
  const { getPlanTier } = usePermissionHelper()
  const planTier = getPlanTier()

  const questionHasCustomizationOptions =
    currentQuestion?.config?.canEditContent ||
    currentQuestion?.config?.canToggleEnable ||
    currentQuestion?.config?.canEditViaDropwn

  const renderUpgradeLink = () => (
    <UpgradeLink
      onClick={() =>
        toggleUpgradeModal(
          true,
          'Upgrade for more customization options!',
          'CUSTOMIZATIONS'
        )
      }>
      upgrade your plan.
    </UpgradeLink>
  )

  // If the entire realm of customizations is not available for the speaker, show a different
  // message than if some of them are available and some are not
  const upgradeNotificationContent = () => {
    if (planTier === PRO_TIER && questionHasCustomizationOptions) {
      return <>To make changes to this question, {renderUpgradeLink()}</>
    }

    if (planTier === LITE_TIER && questionHasCustomizationOptions) {
      return (
        <>To make changes or to skip this question, {renderUpgradeLink()}</>
      )
    }

    return false
  }

  const showUpgradeNotice = () => {
    if (!questionHasCustomizationOptions || planTier === ELITE_TIER) {
      return false
    }

    // TODO:
    // Change back once we lock down permissions
    return false // upgradeNotificationContent()
  }

  return (
    showUpgradeNotice() && (
      <Notification
        variant="blueWarning"
        hideIcon={false}
        hideClose={true}
        maxWidth={true}>
        {upgradeNotificationContent()}
      </Notification>
    )
  )
}

export default CodeBuilderUpgradeNotification
