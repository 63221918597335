import { styled } from '@mui/system'
import { Typography } from '@mui/material'

import { ReactComponent as Emoji1 } from '../../icons/emojiIcon_1.svg'
import { ReactComponent as Emoji2 } from '../../icons/emojiIcon_2.svg'
import { ReactComponent as Emoji3 } from '../../icons/emojiIcon_3.svg'
import { ReactComponent as Emoji4 } from '../../icons/emojiIcon_4.svg'
import { ReactComponent as Emoji5 } from '../../icons/emojiIcon_5.svg'

const EmojiRatingContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
})

const EmojiWrapper = styled('span', {
  shouldForwardProp: (prop) => prop !== 'emojiSize',
})(({ emojiSize }) => ({
  svg: {
    width: emojiSize,
    height: emojiSize,
  },
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}))

const EmojiRating = ({ answerValue, answerText, emojiSize }) => {
  const emojiList = [
    {
      icon: <Emoji1 />,
      value: '1',
    },
    {
      icon: <Emoji2 />,
      value: '2',
    },
    {
      icon: <Emoji3 />,
      value: '3',
    },
    {
      icon: <Emoji4 />,
      value: '4',
    },
    {
      icon: <Emoji5 />,
      value: '5',
    },
  ]

  const emoji = emojiList.find((e) => e.value === answerValue)

  return (
    <EmojiRatingContainer>
      <EmojiWrapper emojiSize={emojiSize}>{emoji?.icon}</EmojiWrapper>
      <StyledTypography variant="body1">{answerText}</StyledTypography>
    </EmojiRatingContainer>
  )
}

export default EmojiRating
