import { styled } from '@mui/system'
import { Typography, Button } from '@mui/material'
import Dropzone from 'react-dropzone'

import { ReactComponent as CloudUploadIcon } from '../../../icons/cloudUploadIcon.svg'
import { ReactComponent as FolderIcon } from '../../../icons/folderIcon_16x16.svg'

import CodeBuilderUploadFile from './CodeBuilderUploadFile'

const DropzoneWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  margin: theme.spacing(3.75, 0),
}))

const DropzoneContainer = styled('div')(({ theme, isDragActive }) => ({
  alignItems: 'center',
  border: isDragActive
    ? `1px solid ${theme.palette.primary.main}`
    : `1px dashed ${theme.palette.border.main}`,
  width: '360px',
  padding: theme.spacing(2.5, 7.5),
  textAlign: 'center',
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius.sm,
  backgroundColor: isDragActive ? theme.palette.action.hover : 'transparent',
  transition: 'background-color 0.3s ease',
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}))

const UploadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  color: theme.palette.base.white,
  height: '32px',
  borderRadius: theme.shape.borderRadius.xs,
  svg: {
    path: {
      stroke: theme.palette.base.white,
    },
  },
}))

const DropzoneText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.hint,
  div: {
    fontWeight: theme.fontWeights.semiBold,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: theme.fontWeights.semiBold,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

const CodeBuilderFileUploader = ({
  surveyOffer,
  uploading,
  acceptedFile,
  uploadProgress,
  handleFileUpload,
  handleUploadCancel,
  handleUploadDelete,
}) => {
  const parseFileRejectionError = (errorCode) => {
    switch (errorCode) {
      case 'file-invalid-type':
        return 'Invalid file type. Please upload a PDF, DOC, or image file.'
      case 'file-too-large':
        return 'File is too large. Please upload a file less than 20MB.'
      default:
        return 'Sorry, something went wrong uploading your file'
    }
  }

  const renderFileRejectionErrors = (fileRejection) => {
    const { errors } = fileRejection
    return errors.map((error) => {
      return (
        <ErrorText variant="body1" component="div">
          {parseFileRejectionError(error.code)}
        </ErrorText>
      )
    })
  }
  return (
    <>
      {!surveyOffer?.fileName && (
        <DropzoneWrapper>
          <Dropzone
            onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}
            accept={{
              'image/*': [],
              'application/pdf': [],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                ['.docx'],
              'application/msword': ['.doc'],
            }} // TODO: check if we need to support more file types
            maxFiles={1}
            maxSize={20000000} // 20MB
          >
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              fileRejections,
            }) => (
              <DropzoneContainer
                {...getRootProps()}
                isDragActive={isDragActive}>
                <input {...getInputProps()} />
                <div>
                  {fileRejections.length > 0 &&
                    renderFileRejectionErrors(fileRejections[0])}
                </div>
                <CloudUploadIcon />
                <DropzoneText variant="body2" component="div">
                  <div>
                    {isDragActive
                      ? 'Drop the files here...'
                      : 'Drag and drop a file to upload'}
                  </div>
                </DropzoneText>
                <DropzoneText variant="body1">
                  {/* We actually currently support way more file types */}
                  PDF, DOC, or any image type up to 20MB
                </DropzoneText>
                {!uploading && (
                  <UploadButton
                    variant="contained"
                    color="primary"
                    startIcon={<FolderIcon />}>
                    Browse Files
                  </UploadButton>
                )}
              </DropzoneContainer>
            )}
          </Dropzone>
        </DropzoneWrapper>
      )}
      {(surveyOffer?.fileName || uploading) && (
        <CodeBuilderUploadFile
          fileName={surveyOffer?.fileName || acceptedFile?.name}
          fileSize={acceptedFile?.size}
          uploading={uploading}
          uploadProgress={uploadProgress}
          handleUploadCancel={handleUploadCancel}
          handleUploadDelete={handleUploadDelete}
        />
      )}
    </>
  )
}

export default CodeBuilderFileUploader
