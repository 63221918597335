import { forwardRef } from 'react'
import { styled } from '@mui/system'
import { Button } from '@mui/material'

import TotalAttendees from './TotalAttendees'
import TalkValue from './TalkValue'
import TalkReattend from './TalkReattend'
import TalkEngagement from './TalkEngagement'
import SocialShareTemplate from './SocialShareTemplate'
import EventPlannerEngagement from './EventPlannerEngagement'

import { downloadComponentAsPng } from '../common/helpers'
import { trackSocialDataShare } from '../../services/metrics'

const ReportDownloadContainer = styled('div')({
  '@media (max-width:960px)': {
    marginTop: '-2000px',
    position: 'absolute',
  },
})

const ReportWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  flex: 1,
})

const DownloadButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(9),
  color: theme.palette.base.white,
}))

const ReportCardStatImage = forwardRef(
  ({ reportData, shareKey, isLoading }, ref) => {
    const showEventPlannerVersion =
      reportData?.speaker?.isEventPlanner &&
      reportData.eventType?.toUpperCase() !== 'KEYNOTE'
    const showEngagementStats =
      reportData.eventType?.toUpperCase() !== 'WORKSHOP'

    const renderTalkEngagement = () => {
      // TODO:
      // Clean this up once we decide how deep to go
      // with the event planner system
      if (showEventPlannerVersion) {
        const sortedBreakdown = reportData.engagementBreakdown?.sort(
          (a, b) => parseFloat(b.avg_value) - parseFloat(a.avg_value)
        )

        // Only show the top 4 in the image
        const statSet = sortedBreakdown.slice(0, 4)

        return (
          <EventPlannerEngagement
            engagementBreakdown={statSet}
            isLoading={isLoading}
          />
        )
      } else {
        return (
          <TalkEngagement
            engagementBreakdown={reportData.engagementBreakdown}
            isLoading={isLoading}
          />
        )
      }
    }

    return (
      <ReportDownloadContainer className="report-download-container">
        <SocialShareTemplate
          reportData={reportData}
          shareKey={shareKey}
          downloadRef={ref}
          color="blue"
          downloadId="report-card-share-image">
          <ReportWrapper>
            <div className="event-report-card__data-summary">
              <TalkValue
                ratingsBreakdown={reportData.ratingsBreakdown}
                isLoading={isLoading}
                eventType={reportData.eventType}
              />
              {showEngagementStats && renderTalkEngagement()}
            </div>
            <div className="event-report-card__data-summary">
              <TalkReattend
                reattendBreakdown={reportData.reattendBreakdown}
                isLoading={isLoading}
                speaker={reportData.speaker}
                eventType={reportData.eventType}
              />
              <TotalAttendees
                totalResponses={reportData.totalResponses}
                isLoading={isLoading}
              />
            </div>
          </ReportWrapper>
        </SocialShareTemplate>
        <DownloadButton
          variant="contained"
          color="primary"
          onClick={() => {
            trackSocialDataShare()
            downloadComponentAsPng(ref, `talkadot-social-share`)
          }}>
          Click To Download
        </DownloadButton>
      </ReportDownloadContainer>
    )
  }
)

export default ReportCardStatImage
