import { styled } from '@mui/system'
import { Typography, Button } from '@mui/material'

import CodeBuilderQuestionHeader from './CodeBuilderQuestionHeader'
import CodeBuilderSettingsCode from './CodeBuilderSettingsCode'
import CodeBuilderSettingsBonus from './CodeBuilderSettingsBonus'
import CodeBuilderSettingsBookingLink from './CodeBuilderSettingsBookingLink'
import CodeBuilderSettingsSocialLinks from './CodeBuilderSettingsSocialLinks'
import CodeBuilderSettingsDownloadSlide from './CodeBuilderSettingsDownloadSlide'
import CodeBuilderSettingsQuestion from './CodeBuilderSettingsQuestion'
import CodeBuilderPossibleAnswer from './CodeBuilderPossibleAnswer'

import CheckBoxStyled from '../../common/CheckBoxStyled'

import { ReactComponent as PlusIcon } from '../../../icons/plusIcon.svg'

const QuestionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: theme.shape.borderRadius.xxs,
}))

const QuestionContainerInner = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '1.25rem',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

const QuestionDisabledOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.base.white,
  opacity: 0.5,
  display: 'flex',
  zIndex: 2,
}))

const AdditionalQuestionConfigOptionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginTop: theme.spacing(3),
}))

const QuestionNumber = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '42px',
  height: '42px',
  padding: '0.625rem',
  marginRight: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius.xs,
  h5: {
    color: theme.palette.base.white,
  },
}))

const QuestionSettingsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
})

const AppendPossibleAnswerButton = styled(Button)(({ theme }) => ({
  height: '42px',
  maxWidth: '150px',
  backgroundColor: theme.palette.base.white,
  borderRadius: theme.shape.borderRadius.xs,
  color: theme.palette.neutral.darkest,
  marginRight: theme.spacing(3.25),
  svg: {
    path: {
      stroke: theme.palette.neutral.darkest,
    },
  },
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(1),
    minWidth: '32px',
    paddingLeft: '8px',
    paddingRight: '8px',
    '& .button-text': {
      display: 'none',
    },
    '& .MuiButton-startIcon': { margin: 0 },
  },
}))

const PossibleAnswerSettingsContainer = styled('div')(({ theme }) => ({
  marginTop: '25px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const MAX_POSSIBLE_ANSWERS_PER_QUESTION = 10

const CodeBuilderQuestion = ({
  surveyOffer,
  question,
  questionNumber,
  updateQuestion,
  updatePossibleAnswer,
  changeQuestionType,
  setCurrentQuestion,
  user,
  code,
  setCode,
  description,
  setDescription,
  updateCode,
  surveyOfferLoading,
  questionLoading,
  speakerName,
  emojiKeywords,
  availableQuestionTypes,
  slideRef,
  setActiveTab,
  canInsertQuestion,
  handleAddQuestion,
  handleDeleteQuestion,
  handleCreatePossibleAnswer,
  handleDeletePossibleAnswer,
}) => {
  const { possibleAnswers } = question

  const renderQuestionSettings = () => {
    switch (question.questionType) {
      case 'code':
        return (
          <CodeBuilderSettingsCode
            surveyOffer={surveyOffer}
            code={code}
            setCode={setCode}
            description={description}
            setDescription={setDescription}
            updateCode={updateCode}
            surveyOfferLoading={surveyOfferLoading}
            setActiveTab={setActiveTab}
          />
        )
      case 'bonus':
        return (
          <CodeBuilderSettingsBonus
            surveyOffer={surveyOffer}
            surveyOfferLoading={surveyOfferLoading}
            updateCode={updateCode}
            setActiveTab={setActiveTab}
          />
        )
      case 'bookingLink':
        return <CodeBuilderSettingsBookingLink setActiveTab={setActiveTab} />
      case 'socialLinks':
        return <CodeBuilderSettingsSocialLinks setActiveTab={setActiveTab} />
      case 'downloadSlide':
        return (
          <CodeBuilderSettingsDownloadSlide
            code={code}
            user={user}
            slideRef={slideRef}
          />
        )
      default:
        return (
          <CodeBuilderSettingsQuestion
            question={question}
            updateQuestion={updateQuestion}
            setCurrentQuestion={setCurrentQuestion}
            questionLoading={questionLoading}
            speakerName={speakerName}
            emojiKeywords={emojiKeywords}
            availableQuestionTypes={availableQuestionTypes}
            setActiveTab={setActiveTab}
          />
        )
    }
  }

  const renderAdditionalQuestionConfigOptions = () => {
    if (question && question.config.canToggleIsPublic) {
      return (
        <AdditionalQuestionConfigOptionsContainer>
          <CheckBoxStyled
            label="Make responses visible on the shareable report"
            checked={question.isAnswerPublic}
            disabled={questionLoading}
            onChange={() => {
              updateQuestion(question.id, {
                is_answer_public: !question.isAnswerPublic,
              })
            }}
          />
        </AdditionalQuestionConfigOptionsContainer>
      )
    }
  }

  const renderPossibleAnswerSettings = () => {
    const filteredPossibleAnswers = possibleAnswers?.filter(
      (possibleAnswer) => !possibleAnswer.config.hiddenFromBuilder
    )

    if (filteredPossibleAnswers?.length > 1) {
      return (
        <PossibleAnswerSettingsContainer>
          {filteredPossibleAnswers.map((possibleAnswer, i) => (
            <CodeBuilderPossibleAnswer
              key={`${possibleAnswer.id}-${i}`}
              question={question}
              possibleAnswer={possibleAnswer}
              questionLoading={questionLoading}
              updatePossibleAnswer={updatePossibleAnswer}
              handleDeletePossibleAnswer={handleDeletePossibleAnswer}
              setCurrentQuestion={setCurrentQuestion}
              setActiveTab={setActiveTab}
            />
          ))}
          {question.config.canAppendPossibleAnswers &&
            filteredPossibleAnswers.length <
              MAX_POSSIBLE_ANSWERS_PER_QUESTION && (
              <AppendPossibleAnswerButton
                variant="outlined"
                startIcon={<PlusIcon />}
                color="tertiary"
                disabled={questionLoading}
                onClick={() => handleCreatePossibleAnswer(question)}>
                <span className="button-text">Add an option</span>
              </AppendPossibleAnswerButton>
            )}
        </PossibleAnswerSettingsContainer>
      )
    }

    return
  }

  return (
    <QuestionContainer>
      <CodeBuilderQuestionHeader
        code={code}
        question={question}
        updateQuestion={updateQuestion}
        changeQuestionType={changeQuestionType}
        questionLoading={questionLoading}
        availableQuestionTypes={availableQuestionTypes}
        canInsertQuestion={canInsertQuestion}
        handleAddQuestion={handleAddQuestion}
        handleDeleteQuestion={handleDeleteQuestion}
      />
      <QuestionContainerInner>
        {!question.enabled && <QuestionDisabledOverlay />}
        <QuestionNumber>
          <Typography variant="h5">{questionNumber}</Typography>
        </QuestionNumber>
        <QuestionSettingsContainer>
          {renderQuestionSettings()}
          {renderPossibleAnswerSettings()}
          {renderAdditionalQuestionConfigOptions()}
        </QuestionSettingsContainer>
      </QuestionContainerInner>
    </QuestionContainer>
  )
}

export default CodeBuilderQuestion
