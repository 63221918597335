import { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/system'
import { Tabs, Tab } from '@mui/material'

import CodeBuilderPreviewQuestionItem from './CodeBuilderPreviewQuestionItem'
import CodeBuilderPreviewPane from './CodeBuilderPreviewPane'

import { DndContext } from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'

import { usePermissionHelper } from '../../../utils/permission_helper'
import { restrictToParentElement } from '@dnd-kit/modifiers'

const PreviewContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '336px',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const StyledTabs = styled(Tabs)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.surface.subtle,
  zIndex: 1,
  ' .MuiTabs-flexContainer': {
    alignItems: 'flex-end',
  },
}))

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 72,
  fontWeight: '400',
  color: theme.palette.text.disabled,
  marginRight: theme.spacing(0.5),
  height: '52px',
  borderRadius: `${theme.shape.borderRadius.xs} ${theme.shape.borderRadius.xs} 0 0`,
  backgroundColor: theme.palette.base.white,
  '&:last-child': {
    marginRight: 0,
  },
  '&.Mui-selected': {
    color: theme.palette.text.inputText,
    fontWeight: '600',
    border: `1px solid ${theme.palette.border.light}`,
    borderBottom: 'none',
  },
  '&:not(.Mui-selected)': {
    border: `6px solid ${theme.palette.border.light}`,
    height: '45px',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}))

const TabContentContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'showTopShadow' &&
    prop !== 'showBottomShadow' &&
    prop !== 'activeTab',
})(({ theme, showTopShadow, showBottomShadow, activeTab }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 312px)',
  maxHeight: activeTab === 1 ? '603px' : '',
  overflowY: 'auto',
  gap: theme.spacing(0.5),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.base.white,
  borderRadius: `0 0 ${theme.shape.borderRadius.xs} ${theme.shape.borderRadius.xs}`,
  border: `1px solid ${theme.palette.border.light}`,
  borderTop: 'none',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    right: 0,
    height: '10px',
    pointerEvents: 'none',
    transition: 'background 0.3s ease, opacity 0.3s ease',
  },
  '&::before': {
    top: '52px',
    background: 'linear-gradient(rgba(0, 0, 0, 0.2), transparent)',
    opacity: showTopShadow ? 1 : 0,
  },
  '&::after': {
    bottom: 0,
    borderRadius: `0 0 ${theme.shape.borderRadius.xs} ${theme.shape.borderRadius.xs}`,
    background: 'linear-gradient(transparent, rgba(0, 0, 0, 0.2))',
    opacity: showBottomShadow ? 1 : 0,
  },
  // Always show scrollbar when content is scrollable
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[400],
    borderRadius: theme.shape.borderRadius.xs,
  },
}))

const CodeBuilderPreviewPaneParent = ({
  surveyOffer,
  currentQuestion,
  currentQuestionNumber,
  setCurrentQuestionId,
  updateQuestion,
  questions,
  user,
  code,
  bonusUrl,
  activeTab,
  setActiveTab,
  missingBonus,
  repositionQuestion,
  handleUpdateSurveyQuestionState,
  surveyQuestions,
  handleDeleteQuestion,
}) => {
  const [showTopShadow, setShowTopShadow] = useState(false)
  const [showBottomShadow, setShowBottomShadow] = useState(false)
  const [isDropAllowed, setIsDropAllowed] = useState(true)

  const contentRef = useRef(null)
  const { canReorderQuestions } = usePermissionHelper()

  const handleScroll = () => {
    const container = contentRef.current
    if (!container) return

    const isScrolledPastTop = container.scrollTop > 0

    const isScrolledPastBottom =
      container.scrollTop + container.clientHeight < container.scrollHeight

    setShowTopShadow(isScrolledPastTop)
    setShowBottomShadow(isScrolledPastBottom)
  }

  useEffect(() => {
    const container = contentRef.current
    if (container) {
      handleScroll()
    }
  }, [questions, currentQuestion, activeTab])

  const movedToStartOfSurvey = (idx) => {
    return idx === 0
  }

  const movedToEndOfSurvey = (idx) => {
    return idx === surveyQuestions.length - 1
  }

  const findNewPreviousQuestion = (idx, newOrderedQuestionIds) => {
    const nextIdx = idx - 1

    const id = newOrderedQuestionIds[nextIdx]
    return questions.find((q) => q.id === id)
  }

  const findNewNextQuestion = (idx, newOrderedQuestionIds) => {
    const nextIdx = idx + 1

    const id = newOrderedQuestionIds[nextIdx]
    return questions.find((q) => q.id === id)
  }

  const handleDragOver = (event) => {
    const { over, active } = event

    if (!over || !active) {
      return
    }

    const newIndex = surveyQuestions.findIndex((item) => item.id === over.id)
    const oldIndex = surveyQuestions.findIndex((item) => item.id === active.id)

    const newQuestions = arrayMove(surveyQuestions, oldIndex, newIndex)
    const newOrderedQuestionIds = newQuestions.map((q) => q.id)

    const newPreviousQuestion = findNewPreviousQuestion(
      newIndex,
      newOrderedQuestionIds
    )

    const newNextQuestion = findNewNextQuestion(newIndex, newOrderedQuestionIds)

    if (
      !movedToStartOfSurvey(newIndex) &&
      !newPreviousQuestion?.config?.canQuestionBeInsertedAfter
    ) {
      setIsDropAllowed(false)
      return
    }

    if (
      !movedToEndOfSurvey(newIndex) &&
      !newNextQuestion?.config?.canQuestionBeInsertedBefore
    ) {
      setIsDropAllowed(false)
      return
    }

    setIsDropAllowed(true)
  }

  const handleDragEnd = async (event) => {
    const { active, over } = event

    if (!active || !over) {
      return
    }

    if (!canReorderQuestions()) {
      // If they somehow get to this point, don't allow any sorting
      return
    }

    if (!isDropAllowed) {
      return
    }

    const existingSurveyQuestions = surveyQuestions

    if (over && active.id !== over.id) {
      const newIndex = surveyQuestions.findIndex((item) => item.id === over.id)
      const oldIndex = surveyQuestions.findIndex(
        (item) => item.id === active.id
      )

      const newQuestions = arrayMove(surveyQuestions, oldIndex, newIndex)

      handleUpdateSurveyQuestionState(newQuestions)
      return await repositionQuestion(
        active.id,
        newIndex,
        existingSurveyQuestions
      )
    }
  }

  const renderTabContent = () => {
    if (activeTab === 0) {
      return questions.map((question, index) => {
        const {
          config: { canToggleEnable },
        } = question

        let isGroupChild = false

        // If question.config.groupingIdentifier is greater or equal to 0,
        // find the group matching the groupingIdentifier in the questions array.
        // Then check if the question is the first question in the group.
        // If it is not the first question in the group, set isGroupChild to true
        if (
          question.config.groupingIdentifier !== null &&
          question.config.groupingIdentifier >= 0
        ) {
          const group = questions.filter(
            (q) =>
              q.config.groupingIdentifier === question.config.groupingIdentifier
          )

          if (group) {
            const groupQuestionIndex = group.findIndex(
              (q) => q.id === question.id
            )
            if (groupQuestionIndex > 0) {
              isGroupChild = true
            }
          }
        }

        return (
          <CodeBuilderPreviewQuestionItem
            key={index}
            question={question}
            surveyOffer={surveyOffer}
            selected={currentQuestion.id === question.id}
            movable={question?.config.movable}
            canToggleEnable={canToggleEnable}
            questionNumber={index + 1}
            setCurrentQuestionId={setCurrentQuestionId}
            updateQuestion={updateQuestion}
            handleDeleteQuestion={handleDeleteQuestion}
            isGroupChild={isGroupChild}
            canReorderQuestions={canReorderQuestions}
            isDropAllowed={isDropAllowed}
            disabled={question.id === 'downloadSlide' && missingBonus}
            tooltipText={
              question.id === 'downloadSlide' && missingBonus
                ? 'Please add a bonus or select the "no bonus" option to download your slide'
                : ''
            }
          />
        )
      })
    } else {
      const {
        config: { canToggleEnable },
      } = currentQuestion

      return (
        <>
          <CodeBuilderPreviewQuestionItem
            question={currentQuestion}
            surveyOffer={surveyOffer}
            selected={true}
            sortable={false}
            canToggleEnable={canToggleEnable}
            questionNumber={currentQuestionNumber}
            setCurrentQuestionId={() => {}}
            updateQuestion={updateQuestion}
            handleDeleteQuestion={handleDeleteQuestion}
            canReorderQuestions={canReorderQuestions}
          />
          <CodeBuilderPreviewPane
            currentQuestion={currentQuestion}
            user={user}
            code={code}
            bonusUrl={bonusUrl}
          />
        </>
      )
    }
  }

  const handleChangeTab = (newValue) => {
    if (questions.length > 1) {
      setActiveTab(newValue)
    }
  }

  return (
    <PreviewContainer>
      <StyledTabs
        value={activeTab}
        onChange={(_, newValue) => handleChangeTab(newValue)}
        variant="fullWidth"
        TabIndicatorProps={{
          style: { display: 'none' },
        }}>
        {questions.length > 1 && <StyledTab label="All Questions" />}
        <StyledTab label="Preview Feedback" disabled={questions.length <= 1} />
      </StyledTabs>
      <TabContentContainer
        ref={contentRef}
        onScroll={handleScroll}
        activeTab={activeTab}
        showTopShadow={showTopShadow}
        showBottomShadow={showBottomShadow}>
        <DndContext
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          modifiers={[restrictToParentElement]}>
          <SortableContext items={questions}>
            {renderTabContent()}
          </SortableContext>
        </DndContext>
      </TabContentContainer>
    </PreviewContainer>
  )
}

export default CodeBuilderPreviewPaneParent
