import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'
import { Typography } from '@mui/material'
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import { Doughnut } from 'react-chartjs-2'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4.5),
  alignItems: 'center',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

const DonutContainer = styled('div')(({ theme }) => ({
  width: '200px',
  height: '200px',
}))

const ProgressBarsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(2.5),
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

const ProgressBarContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}))

const LabelContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

const StyledLinearProgress = styled(LinearProgress)(({ theme, color }) => ({
  height: '8px',
  borderRadius: theme.shape.borderRadius.sm,
  backgroundColor: theme.palette.neutral.veryLight,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '8px',
    backgroundColor: color ? '' : theme.palette.neutral.semiLight,
  },
}))

const CustomQuestionBoolean = ({ question }) => {
  const theme = useTheme()

  const totalAnswers = question.answers?.length || 0
  // Answers can come in with Yes + an emoji, so scope to making
  // sure it just includes 'yes'
  const yesAnswers =
    question.answers?.filter((answer) => answer.text.includes('Yes')).length ||
    0
  const noAnswers = totalAnswers - yesAnswers
  const yesPercentage =
    totalAnswers > 0 ? Math.round((yesAnswers / totalAnswers) * 100) : 0
  const noPercentage =
    totalAnswers > 0 ? Math.round((noAnswers / totalAnswers) * 100) : 0

  const chartData = {
    labels: ['No', 'Yes'],
    datasets: [
      {
        data: [noAnswers, yesAnswers],
        backgroundColor: [
          theme.palette.neutral.semiLight,
          theme.palette.secondary.main,
        ],
        borderWidth: 0,
        cutout: '70%',
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <Container>
      <DonutContainer>
        <Doughnut data={chartData} options={options} />
      </DonutContainer>
      <ProgressBarsContainer>
        <ProgressBarContainer>
          <LabelContainer>
            <Typography variant="body1">Yes</Typography>
            <Typography variant="body1">{yesPercentage}%</Typography>
          </LabelContainer>
          <StyledLinearProgress
            variant="determinate"
            color="secondary"
            value={(yesAnswers / totalAnswers) * 100}
          />
        </ProgressBarContainer>
        <ProgressBarContainer>
          <LabelContainer>
            <Typography variant="body1">No</Typography>
            <Typography variant="body1">{noPercentage}%</Typography>
          </LabelContainer>
          <StyledLinearProgress
            variant="determinate"
            value={(noAnswers / totalAnswers) * 100}
          />
        </ProgressBarContainer>
      </ProgressBarsContainer>
    </Container>
  )
}

export default CustomQuestionBoolean
