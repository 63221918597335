import { styled } from '@mui/system'
import { Typography } from '@mui/material'

import { ReactComponent as ResponsesSvg } from '../../icons/attendeesHeaderIcon.svg'
import { ReactComponent as ValuableSvg } from '../../icons/valuableHeaderIcon.svg'
import { ReactComponent as ReattendSvg } from '../../icons/reattendHeaderIcon.svg'

const CardContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'minWidth',
})(({ theme, minWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: minWidth || '',
  minHeight: '185px',
  borderRadius: theme.shape.borderRadius.sm,
  padding: theme.spacing(1.5),
  border: `1px solid ${theme.palette.neutral.extraLight}`,
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    minWidth: '100%',
  },
}))

const CardHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const HeaderLogo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1),
}))

const HeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.veryDark,
}))

const CardContent = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
})

export const DonutStatsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  marginTop: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
  },
}))

export const PrimaryDonutContainer = styled('div')({
  width: '124px',
  height: '124px',
})

export const SecondaryDonutsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

export const SecondaryDonutsColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(1.5),
}))

export const SecondaryDonutStatContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(0.5),
}))

export const SecondaryDonutContainer = styled('div')({
  width: '40px',
  height: '40px',
  minWidth: '40px',
  minHeight: '40px',
})

const StatCard = ({
  type,
  ratingsBreakdown,
  reattendBreakdown,
  eventType,
  speakerName,
  children,
  minWidth,
}) => {
  const calculateValuableRating = ({ positives, total }) => {
    return parseInt((positives / total) * 100) || '0'
  }

  const calculateReattendRate = ({ d_yes, p_yes, total_reattend_answers }) => {
    return parseInt(((d_yes + p_yes) / total_reattend_answers) * 100) || '0'
  }

  const valuableHeaderContent = () => {
    if (eventType?.toUpperCase() === 'GLOBAL') {
      return ' found this event valuable'
    }

    if (eventType?.toUpperCase() === 'TOTAL_STATISTICS') {
      return ' found the sessions valuable'
    }

    return ' found this session valuable'
  }

  const reattendHeaderContent = () => {
    if (!eventType && !speakerName) {
      return ' would hear this speaker again'
    }

    if (!eventType && speakerName) {
      return ` would hear ${speakerName} speak again`
    }

    if (eventType.toUpperCase() === 'GLOBAL') {
      return ' would attend this event again'
    }

    if (eventType.toUpperCase() === 'WORKSHOP') {
      return ' would attend a session like this again'
    }

    if (eventType.toUpperCase() === 'TOTAL_STATISTICS') {
      return ' would hear the presenters again'
    }

    return ' would hear this speaker again'
  }

  const headerText = (type) => {
    switch (type) {
      case 'responses':
        return 'Attendee Responses'
      case 'valuable':
        const { positives, total } = ratingsBreakdown
        return `${calculateValuableRating({
          positives,
          total,
        })}% ${valuableHeaderContent()}`
      case 'reattend':
        const { d_yes, p_yes, total_reattend_answers } = reattendBreakdown
        return `${calculateReattendRate({
          d_yes,
          p_yes,
          total_reattend_answers,
        })}% ${reattendHeaderContent()}`
      case 'emojis':
        return 'Attendees found this session'
      default:
        return ''
    }
  }

  const headerLogo = (type) => {
    switch (type) {
      case 'responses':
        return <ResponsesSvg />
      case 'valuable':
        return <ValuableSvg />
      case 'reattend':
        return <ReattendSvg />
      default:
        return <ResponsesSvg />
    }
  }

  return (
    <CardContainer minWidth={minWidth}>
      <CardHeader>
        <HeaderLogo>{headerLogo(type)}</HeaderLogo>
        <HeaderText variant="h6">{headerText(type)}</HeaderText>
      </CardHeader>
      <CardContent>{children}</CardContent>
    </CardContainer>
  )
}

export default StatCard
