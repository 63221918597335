import { styled } from '@mui/system'
import { Typography, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import moment from 'moment'

import { ReactComponent as CheckIcon } from '../../icons/checkMarkIcon_16x16.svg'
import { ReactComponent as EliteIcon } from '../../icons/membershipPlanIconElite.svg'
import { ReactComponent as ProIcon } from '../../icons/membershipPlanIconPro.svg'
import { ReactComponent as LiteIcon } from '../../icons/membershipPlanIconLite.svg'
import { ReactComponent as InfoIcon } from '../../icons/infoIcon_16x16.svg'

import { cleanBulletItem } from './helpers'
import { renderCurrency } from '../../utils/number'

const PlanDetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '280px',
  maxWidth: '550px',
}))

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3.5, 6),
  backgroundColor: theme.palette.base.white,
}))

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.25),
  marginBottom: theme.spacing(3),
}))

const FeatureBulletsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  rowGap: theme.spacing(3),
  columnGap: theme.spacing(5),
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
  },
}))

const FeatureBullet = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  svg: {
    width: '10px',
    minWidth: '10px',
    marginRight: theme.spacing(2.5),
    marginTop: '3px',
    path: {
      stroke: theme.palette.primary.main,
    },
  },
}))

const PlanPriceSummary = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),
  padding: theme.spacing(1.5, 0),
  borderTop: `1px solid ${theme.palette.border.main}`,
  borderBottom: `1px solid ${theme.palette.border.main}`,
}))

const PlanNameWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

const ProRateFootnote = styled(Typography)({
  textAlign: 'end',
})

const ProRatedTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: theme.palette.primary.lightest,
    padding: '15px',
    border: `1px solid ${theme.palette.base.black}`,
  },
}))

const ProRatedIcon = styled(InfoIcon)(({ theme }) => ({
  fill: theme.palette.base.black,
  height: '20px',
  width: '20px',
}))

const PlanDetails = ({
  selectedPlan,
  upgradingPaidPlan,
  isTrialing,
  nextBillingDate,
}) => {
  const theme = useTheme()
  const belowLargeScreen = useMediaQuery(theme.breakpoints.down('lg'))

  const planIcon = () => {
    switch (selectedPlan?.name) {
      case 'Talkadot Lite':
        return <LiteIcon />
      case 'Talkadot Pro':
        return <ProIcon />
      case 'Talkadot Elite':
        return <EliteIcon />
      default:
        return <LiteIcon />
    }
  }

  const splitFeatureBullets = () => {
    const featureBullets = selectedPlan?.featureBullets || []
    const midIndex = Math.ceil(featureBullets.length / 2)
    const firstColumn = featureBullets.slice(0, midIndex)
    const secondColumn = featureBullets.slice(midIndex)
    return { featureBullets, firstColumn, secondColumn }
  }

  const { featureBullets, firstColumn, secondColumn } = splitFeatureBullets()

  // allows multiple columns of feature bullets where adjacent bullets are from different columns
  // but still maintain a consistent grid cell height between columns
  const interLeavedFeatureBullets = () => {
    // insert a bullet from the second column after every bullet in the first column
    const interLeavedBullets = firstColumn.reduce((acc, bullet, i) => {
      acc.push(bullet)
      if (secondColumn[i]) {
        acc.push(secondColumn[i])
      }
      return acc
    }, [])
    return interLeavedBullets
  }

  const bullets = belowLargeScreen
    ? featureBullets
    : interLeavedFeatureBullets()

  let annualPrice = selectedPlan?.price
  let monthlyPrice = selectedPlan?.price / 12
  let proratedPriceAdjusted = null

  if (upgradingPaidPlan && selectedPlan.proratedPrice) {
    annualPrice = selectedPlan.proratedPrice
    proratedPriceAdjusted = selectedPlan.price
  }

  if (isTrialing && selectedPlan?.hasOneTimeDiscount) {
    annualPrice = selectedPlan?.discountedPrice
    monthlyPrice = selectedPlan?.discountedPrice / 12
  }

  if (
    isTrialing &&
    (selectedPlan?.hasRecurringDiscount || selectedPlan?.hasImpliedDiscount)
  ) {
    annualPrice = selectedPlan?.discountedPrice
    monthlyPrice = selectedPlan?.discountedPrice / 12
  }

  const showProRatedTooltip = upgradingPaidPlan && selectedPlan.proratedPrice

  const makeTooltipTitle = () => (
    <>
      <Typography>
        <p>
          <b>Explaining the amount you are paying today:</b>
        </p>
        <p>
          The amount you're paying today is the per day cost of the new plan
          remaining in your billing cycle minus what you have already paid on
          your current plan for those days.
        </p>
        <p>
          We calculate this by taking the annual price of the new plan ($
          {selectedPlan?.price}), dividing it by 365 to get the daily rate, and
          then multiplying by the number of days left until your renewal date.
          From this amount, we subtract what you've already paid towards your
          current plan for the same period.
        </p>
        <p>
          This means you're only paying the difference for the upgrade during
          the remaining time of your current membership year.
        </p>
        <p>
          At your next renewal, your membership will renew at the full annual
          price of the new plan.
        </p>
      </Typography>
    </>
  )

  return (
    <PlanDetailsContainer>
      <ContentWrapper>
        <Header>
          {planIcon()}
          <Typography variant="h5" color="primary">
            {selectedPlan.planType} Plan Details
          </Typography>
        </Header>
        <FeatureBulletsContainer>
          {bullets.map((item, i) => (
            <FeatureBullet key={i}>
              <CheckIcon />
              <Typography>{cleanBulletItem(item)}</Typography>
            </FeatureBullet>
          ))}
        </FeatureBulletsContainer>
        <PlanPriceSummary>
          <PlanNameWrapper>
            <Typography variant="h6">{`${selectedPlan.billingFrequency} ${selectedPlan.planType} plan`}</Typography>
            {selectedPlan.billingFrequency === 'Annual' && (
              <Typography variant="body1">
                ({renderCurrency(monthlyPrice)} <span>/month paid yearly)</span>
              </Typography>
            )}
          </PlanNameWrapper>
          <Typography variant="h6">
            ${annualPrice}
            {proratedPriceAdjusted && ' today'}
            {showProRatedTooltip && (
              <ProRatedTooltip title={makeTooltipTitle()} disableInteractive>
                <ProRatedIcon />
              </ProRatedTooltip>
            )}
          </Typography>
        </PlanPriceSummary>
        {proratedPriceAdjusted && (
          <ProRateFootnote variant="body1">
            {`$${proratedPriceAdjusted} when your plan renews ${moment(
              nextBillingDate
            ).format('MMMM D, YYYY')}`}
          </ProRateFootnote>
        )}
      </ContentWrapper>
    </PlanDetailsContainer>
  )
}

export default PlanDetails
