import { useEffect, useState } from 'react'
import { styled } from '@mui/system'

import SpeakerSlide from '../../common/SpeakerSlideResponsive'
import AlertCard from '../../common/AlertCard'
import AlertCardCta from '../../common/AlertCardCta'
import { ReactComponent as Arrow } from '../../../icons/arrow.svg'

const AlertContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'windowWidth',
})(({ windowWidth }) => ({
  position: 'absolute',
  top: '-10%',
  left: `calc(-200px + ${
    windowWidth > 960 + 540
      ? '0px'
      : `${Math.min(180, 960 + 540 - windowWidth)}px`
  })`,
  '@media (max-width: 1132px)': {
    left: `calc(-200px + ${
      windowWidth > 640 + 540
        ? '0px'
        : `${Math.min(180, 640 + 540 - windowWidth)}px`
    })`,
    top: '-30%',
  },
  '@media (max-width: 850px)': {
    left: `calc(-200px + ${
      windowWidth > 480 + 540
        ? '0px'
        : `${Math.min(180, 480 + 540 - windowWidth)}px`
    })`,
    top: '-50%',
  },
}))

const ArrowContainer = styled('div')({
  position: 'absolute',
  top: '150px',
  left: '30px',
  transform: 'rotate(210deg) scaleX(-1)',
  '@media (max-width: 1132px)': {
    top: '85px',
    left: '-10px',
    transform: 'rotate(210deg) scaleX(-1)',
  },
  '@media (max-width: 850px)': {
    top: '60px',
    left: '-15px',
    transform: 'rotate(210deg) scaleX(-1)',
  },
})

const CtaContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '1.375rem',
})

const SurveyOffersOnboardingStepSix = ({
  user,
  advanceLastStep,
  loading,
  code,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      <SpeakerSlide
        avatar={user.avatar}
        surveyLink={user.shortlink}
        socialLinks={user.socialLinks}
        email={user.email}
        name={user.first_name}
        size={'lg'}
        code={code}
      />
      <AlertContainer windowWidth={windowWidth}>
        <AlertCard
          title="Each Code you create will generate a slide"
          width={400}>
          When you use this slide or QR code, your audience will automatically
          be taken to your feedback form with the settings and bonus that match
          this code.
          <CtaContainer>
            <AlertCardCta
              buttonText="Continue"
              buttonWidth="160px"
              handleClick={() => advanceLastStep(2)}
              loading={loading}
            />
          </CtaContainer>
        </AlertCard>
      </AlertContainer>
      <ArrowContainer>
        <Arrow />
      </ArrowContainer>
    </>
  )
}

export default SurveyOffersOnboardingStepSix
