import { styled } from '@mui/system'
import { Typography, Tooltip } from '@mui/material'

import { ReactComponent as EyeIcon } from '../../icons/eyeIcon_16x16.svg'
import { ReactComponent as EyeOffIcon } from '../../icons/eyeOffIcon_16x16.svg'

const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  minHeight: '185px',
  borderRadius: theme.shape.borderRadius.sm,
  padding: theme.spacing(4),
  border: `1px solid ${theme.palette.neutral.extraLight}`,
  flex: 1,
}))

const CardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}))

const CardHeaderLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const CardHeaderRight = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}))

const QuestionPublicIcon = styled('div')(({ theme, isQuestionPublic }) => ({
  svg: {
    path: {
      stroke: isQuestionPublic
        ? theme.palette.neutral.veryDark
        : theme.palette.error.semiDark,
    },
  },
}))

const CardContent = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
})

const ResponseCountContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main,
}))

const CustomQuestionCard = ({ question, isEventOwner, children }) => {
  const renderQuestionType = () => {
    switch (question.question_type) {
      case 'MULTIPLE_CHOICE':
        return 'Multiple Choice'
      case 'FREE_FLOW':
        return 'Long Text'
      case 'EMOJI_SCALE':
        return '5 Point Scale'
      case 'BOOLEAN':
        return 'Yes / No'
      default:
        return ''
    }
  }

  const totalResponses = question.answers.length

  return (
    <CardContainer>
      <CardHeader>
        <CardHeaderLeft>
          <Typography variant="body2">{renderQuestionType()}</Typography>
          <Typography variant="h4">{question.content}</Typography>
          <ResponseCountContent variant="body2">
            {totalResponses} {totalResponses === 1 ? 'response' : 'responses'}
          </ResponseCountContent>
        </CardHeaderLeft>
        <CardHeaderRight>
          {isEventOwner && (
            <QuestionPublicIcon isQuestionPublic={question.isQuestionPublic}>
              <Tooltip
                title={
                  question.isQuestionPublic
                    ? 'Visible to everyone'
                    : 'Only visible to you'
                }
                enterTouchDelay={0}
                leaveTouchDelay={3000}>
                {question.isQuestionPublic ? <EyeIcon /> : <EyeOffIcon />}
              </Tooltip>
            </QuestionPublicIcon>
          )}
        </CardHeaderRight>
      </CardHeader>
      <CardContent>{children}</CardContent>
    </CardContainer>
  )
}

export default CustomQuestionCard
